import React, { Component } from 'react'
import Header from '../common/Header'; 
import Sidebar from './Sidebar'; 
import adminImg from '../../images/dashboard/admin-img.png';
import {getAllNotification, userInfo, updateNotificationStatus, updateAppUpdateNotificationStatus } from "../../helper/service";
import Loader from '../Loader';
import SuccessPopup from './SuccessPopup';


export default class NotificationSettings extends Component {

  constructor(props) {
    super(props); 
    this.state = {
        userData: '',
        isload: false,
        memberUpdate:'',
        memberEngagement:'',
        is_loader:false
    };
}
    

async changeStatus(idd,status){
    this.setState({is_loader:true,is_success:false});
    var userId = localStorage.getItem('user_id');
    let data = {  
      id:idd,
      status:status, 
      userId:userId, 
      identityValue:4
    } 
    await updateNotificationStatus(data);   
    this.getNotifications();
    this.setState({is_loader:false,is_success:true});
}

async changeStatusEngagement(idd,status){
  this.setState({is_loader:true,is_success:false});
    var userId = localStorage.getItem('user_id');
    let data = {  
      id:idd,
      status:status, 
      userId:userId, 
      identityValue:3
    } 
    await updateNotificationStatus(data);   
    this.getNotifications();
    this.setState({is_loader:false,is_success:true});
}




appUpdateIterate(memberUpdate){ 
    if(memberUpdate.length > 0){ 
      const memberUpdate1 = memberUpdate.map(function(subs){
        if(subs.identityValue==3){
            if(subs.status=="Inactive"){
                var status1 = false;  
                var stts = "Active"; 
            } else {
                var status1 = true;  
                var stts = "Inactive"; 
            }
            return(
              <div className="d-flex justify-content-between">
              <p className="notification-text">{subs.name}11</p>
              <div className="custom-control custom-switch">
                <input type="checkbox" checked={status1}  className="custom-control-input" id={subs._id} onChange={this.changeStatusEngagement.bind(this,subs._id,stts)} />
                <label className="custom-control-label" htmlFor={subs._id} />
              </div>
            </div> 
          )
        }
      },this) 
      this.setState({
        memberUpdate:memberUpdate1
      })
      } 
    }  


    libraryEngagementIterate(memberUpdate){
        if(memberUpdate.length > 0){ 
            const memberUpdate1 = memberUpdate.map(function(subs){
              if(subs.identityValue==4){
                if(subs.status=="Inactive"){
                    var status1 = false;  
                    var stts = "Active"; 
                } else {
                    var status1 = true;  
                    var stts = "Inactive"; 
                }
                return(
                  <div className="d-flex justify-content-between">
                  <p className="notification-text">{subs.name}</p>
                  <div className="custom-control custom-switch">
                    <input type="checkbox" checked={status1} className="custom-control-input" id={subs._id} onChange={this.changeStatus.bind(this,subs._id,stts)}/>
                    <label className="custom-control-label" htmlFor={subs._id} />
                  </div>
                </div>  
              )
            }
          }, this) 
          this.setState({
            memberEngagement:memberUpdate1
          })
          } 
        }



        async getNotifications(){ 
          const userId = localStorage.getItem('user_id');
          getAllNotification(userId).then(data => {
              this.libraryEngagementIterate(data.result);
              this.appUpdateIterate(data.result);
          })
      } 



        getuserInfo(){
          const userId = localStorage.getItem('user_id');
          userInfo(userId).then(data => {
              this.setState({ userData: data.result, isload: true })
              // this.libraryEngagementIterate(data.result);
              // this.appUpdateIterate(data.result);
          })
        }

        componentDidMount() { 
           this.getuserInfo();
           this.getNotifications();
        }
    
  
    render() {
        return (
        <div className="wrapper">    
        <Header/> 
        {this.state.is_loader ? (<Loader/>) : (<></>)}
        {this.state.is_success ? (<SuccessPopup message="Notification Details Updated Successfully"/>) : (<></>)}
        <div className="content-wrapper">    
          <section className="profile">      
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                 <Sidebar/>
                </div>
                <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                  <div className="tab-content" id="profile-tabContent"> 
                    <div className="tab-pane fade active show" id="notifications-settings" role="tabpanel" aria-labelledby="notifications-settings-tab">
                      <h2 className="title">Notifications Settings</h2>
                      <h3 className="sub-title">App Updates</h3>
                      {this.state.memberUpdate}     
                      <h3 className="sub-title">Library Updates</h3> 
                      {this.state.memberEngagement} 
                      {/* <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-white">Cancel</button>
                        <button type="submit" className="btn btn-blue">Save</button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
             </div>
        )
    }
}
   
