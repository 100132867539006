import React from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";

export default function SubcribeStatus() {
  const brandColor = localStorage.getItem("subadminBrandColor") ?? "";
  const boxStyle = {
    backgroundColor: brandColor,
    color: "white",
  };

  return (
    <div className="main-subs-expired-div ">
      <Header />
      <p className="support-img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="920px"
          viewBox="0 0 255 282.984"
        ></svg>
      </p>

      <div className="inner-subs-div">
        <div className="payment-failed">
          <img />
          <p className="text" style={{ color: brandColor }}>
            Wisdome gives memberships, courses and coaching programs access to
            many content sharing features....
          </p>
          <div className="failed-payment-div" style={boxStyle}>
            <label>Hmmmm</label>
            <img />
            <label>That didn't work?</label>
            <label>Please buy a subscription</label>
          </div>
          <Link to="/account-details" className="back-to-subs">
            <label style={{ color: brandColor }}>GO TO SUBSCRIPTION</label>
          </Link>
        </div>
      </div>
    </div>
  );
}
