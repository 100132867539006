import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import { Container, Row, Col, Table } from "react-bootstrap";
import { getAllInvoices } from "../../helper/service";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";

export default function Invoices() {
  const [transactions, setTransactions] = useState([]);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    memberName: "",
  });
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
    memberName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    getAllInvoices().then((data) => {
      if (data.status === 200 && data.result?.success) {
        setTransactions([...data.result?.invoices]);
      }
    });
  }, []);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = { ...errors };
    let isValid = true;

    if (!formData.startDate) {
      isValid = false;
      newErrors.startDate = "Start Date is required.";
    } else {
      newErrors.startDate = "";
    }

    if (!formData.endDate) {
      isValid = false;
      newErrors.endDate = "End Date is required.";
    } else {
      newErrors.endDate = "";
    }

    if (!formData.memberName) {
      isValid = false;
      newErrors.memberName = "Member Name is required.";
    } else {
      newErrors.memberName = "";
    }

    setErrors(newErrors);

    if (isValid) {
      console.log("Form data:", formData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleExport = () => {
    const csvData = transactions.map((transaction) => ({
      ID: transaction?.payment.id,
      Customer: transaction?.payment.metadata?.email,
      "Date/Time": transaction?.payment.metadata?.date,
      "Amount ($)": `${
        transaction?.payment.amount / 100
      } ${transaction?.payment.currency.toUpperCase()}`,
      "Payment Method": `${
        transaction?.payment.payment_method_types.length > 0
          ? transaction?.payment.payment_method_types
          : "Unknown"
      }`,
      Status: transaction?.payment.status,
      "View Receipt": transaction.receiptUrl,
    }));

    if (transactions.length === 0) {
      alert("No data to export.");
      return;
    }

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "invoices.csv";
    link.click();
  };

  const handleExit = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <div className="content-wrapper admin-social-wall">
        {/* <Container className='mt-3'>
                    <h3>Invoices</h3>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Start Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="startDate"
                                        value={formData.startDate}
                                        onChange={handleChange}
                                        placeholder="Select Start Date"
                                        required
                                        style={{ height: '2.5rem' }}
                                    />
                                    <div className="text-danger">{errors.startDate}</div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>End Date:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={formData.endDate}
                                        onChange={handleChange}
                                        placeholder="Select End Date"
                                        required
                                        style={{ height: '2.5rem' }}
                                    />
                                    <div className="text-danger">{errors.endDate}</div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Member Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="memberName"
                                        value={formData.memberName}
                                        onChange={handleChange}
                                        placeholder="Enter Member name"
                                        required
                                        style={{ height: '2.5rem' }}
                                    />
                                    <div className="text-danger">{errors.memberName}</div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="align-self-end">
                                <Button type="submit" variant='secondary'>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <hr></hr>
                </Container> */}

        <div className="d-flex flex-column">
          <Row>
            <Col md={1} className="align-self-start mt-3 ml-3">
              <button
                onClick={handleExit}
                variant="secondary"
                className="submit-btn px-3 py-2 invoice-btn"
              >
                Back
              </button>
            </Col>

            <Col md={1} className="align-self-start mt-3">
              <button
                onClick={handleExport}
                variant="secondary"
                className="submit-btn py-2 invoice-btn"
              >
                Export
              </button>
            </Col>
          </Row>
        </div>

        <Container className="mt-2">
          <Row>
            <div className="table-responsive mt-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date/Time</th>
                    <th>Amount ($)</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>View Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction?.payment.id}</td>
                      <td>{transaction?.payment.metadata?.date}</td>
                      <td>{`${
                        transaction?.payment.amount / 100
                      } ${transaction?.payment.currency.toUpperCase()}`}</td>
                      <td>{`${
                        transaction?.payment.payment_method_types.length > 0
                          ? transaction?.payment.payment_method_types
                          : "Unknown"
                      }`}</td>
                      <td>{transaction?.payment.status}</td>
                      <td>
                        <a
                          className="btn btn-secondary"
                          target="_blank"
                          href={transaction.receiptUrl}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};