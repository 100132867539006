import React, { Component } from 'react';
import Header from '../common/Header'; 
import book2 from "../../images/admin/book2.svg";
import book1 from "../../images/admin/book.svg";
import visibility from '../../images/admin/visibility (2).svg';
import heart from "../../images/audio/heart (4).svg"
import general from "../../images/dashboard/elm-general-photo-5.png";
import { getFolders, getSubFolder } from "../../helper/service";
import Folder from "./Folder";
import SubFolder from "./Sub-Folder";
export default class Content extends Component {
  constructor(props){
    super(props)
    this.state={
      folders:[],
      subFolder:[],
      selectFolder:{}
    }
  }

  async componentDidMount(){
    let subadminId = localStorage.getItem('subadminId')
    let data = await getFolders(subadminId);
    let da =  await getSubFolder(data.result[0]._id);
    this.setState({folders:data.result});
    this.setState({selectFolder:data.result[0]})
    
    this.setState({subFolder:da.result})
  }

  handleFolderClick = async (_id) => {
    // let folders = {...this.state.folders}
    let folder = this.state.folders.filter((folder) => folder._id === _id);
   
    this.setState({selectFolder:folder[0]})
    let data =  await getSubFolder(_id);
    this.setState({subFolder:data.result})
  }

  render(){
    const { folders,selectFolder, subFolder } = this.state

   return (
   <div className="wrapper">
      <Header/>
      <div className="content-wrapper">   
          <section className="content-library">       
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center border-line">
                    <h2 className="title">Folders</h2>            
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4">
                  <div className="most-popular-content">
                    <div className="card text-center">
                      <div className="card-body">
                        <h5 className="title">Most Popular Content</h5>
                        <div className="popular-slider owl-carousel owl-theme">
                          <div className="item">
                            <img src={book2} alt="" className="slider-img" />
                            <h5 className="book-title">Leaving Winter</h5>
                            <div className="d-flex align-items-center justify-content-center">
                              <img src={visibility} className="comm" />
                              <p className="views"> 101 Views | 45 Comments </p>
                              <img src={heart} className="fav-icon" />
                            </div>
                          </div>
                          <div className="item">
                            <img src={book1} alt="" className="slider-img" />
                            <h5 className="book-title">Leaving Winter</h5>
                            <div className="d-flex align-items-center justify-content-center">
                              <img src={visibility} className="comm" />
                              <p className="views"> 101 Views | 45 Comments </p>
                              <img src={heart} className="fav-icon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-files">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="title">Content files <span><i className="fas fa-filter" /></span> <a className="view-all">View all <i className="fas fa-angle-right" /></a></h5>
                        <Folder folder={selectFolder}/>
                     { subFolder.length !==0 ? subFolder.map((subfolder) => {
                       return (
                        <SubFolder folder={subfolder}/>
                       )
                     }): ""}   
                      </div>
                    </div>              
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card about-book">
                    <div className="card-body">
                      <div className="book-views">
                        <div className="d-flex justify-content-center">
                          <img src={book2} className="book-img" />
                          <div className="d-flex flex-column side-icon">
                            <h5 className="book-title">Leaving Winter</h5>
                            <img src={heart} width={30} height={27} />
                            <p className="book-text">Reading Now</p>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <img src={book2} className="left-round-img mr-3" />
                        <div className="media-body">
                          <h5 className="book-title">Leaving Winter <span className="post-time">Posted in March 27, 2019</span></h5>
                          <h5 className="folder-files">Containing 1 Sub-folder 28 Files </h5>
                        </div>
                      </div>
                      <p className="book-description">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.</p>
                    </div>
                  </div>
                  <div className="card book-comments">
                    <div className="card-body">
                      <h5 className="title">Comments <span className="send">Send <i className="fas fa-paper-plane" /></span></h5>
                      <textarea className="form-control share-thought" placeholder="Share your thoughts" rows={3} defaultValue={""} />
                      <div className="media">
                        <img src={general} className="left-round-img mr-3" />
                        <div className="media-body">
                          <h5 className="name">Garrett Watson <span className="time">12:04</span></h5>
                          <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>
                        </div>
                      </div>
                      <div className="media">
                        <img src={general} className="left-round-img mr-3" />
                        <div className="media-body">
                          <h5 className="name">Garrett Watson <span className="time">12:04</span></h5>
                          <p className="comment">Content here, content here', making it look like readable English :) </p>
                        </div>
                      </div>
                      <div className="media">
                        <img src={general} className="left-round-img mr-3" />
                        <div className="media-body">
                          <h5 className="name">Garrett Watson <span className="time">12:04</span></h5>
                          <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>
                        </div>
                      </div>
                      <div className="media">
                        <img src={general} className="left-round-img mr-3" />
                        <div className="media-body">
                          <h5 className="name">Garrett Watson <span className="time">12:04</span></h5>
                          <p className="comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="card folders">
                    <div className="card-body">
                      <h5 className="title">Folders </h5>
                      <div className="search-content">
                        <input type="text" name className="form-control" placeholder="Search" />
                        <span className="search-icon"><i className="fas fa-search" /></span>
                      </div>
                      <h5 className="folder-info">{folders.length} total folders <a className="view-all">View all <i className="fas fa-angle-right" /></a></h5>
                      <div className="scroll">
                        {folders.slice(6,50).map((folder) => {
                          return (
                            <div className="book">
                            <img src={folder.image} className="img-fluid mx-auto d-block" onClick={() => this.handleFolderClick(folder._id)}/>
                            <h5 className="book-title">{folder.title}</h5>
                            <p className="desc">Containing {folder.totalcontent} Pages and {folder.totalsubfolder} Subfolder</p>
                          </div>
                          )
                        })}
                        {/* <div className="book">
                          <img src={book1} className="img-fluid mx-auto d-block" />
                          <h5 className="book-title">Miss Makeover</h5>
                          <p className="desc">Containing 28 Pages</p>
                        </div>
                        <div className="book">
                          <img src={book1} className="img-fluid mx-auto d-block" />
                          <h5 className="book-title">Miss Makeover</h5>
                          <p className="desc">Containing 28 Pages</p>
                        </div>
                        <div className="book">
                          <img src={book1} className="img-fluid mx-auto d-block" />
                          <h5 className="book-title">Miss Makeover</h5>
                          <p className="desc">Containing 28 Pages</p>
                        </div>
                        <div className="book">
                          <img src={book1} className="img-fluid mx-auto d-block" />
                          <h5 className="book-title">Miss Makeover</h5>
                          <p className="desc">Containing 28 Pages</p>
                        </div>
                        <div className="book">
                          <img src={book1} className="img-fluid mx-auto d-block" />
                          <h5 className="book-title">Miss Makeover</h5>
                          <p className="desc">Containing 28 Pages</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> 
    </div>
   )
  }
}