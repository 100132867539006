import React from "react";
import rightArrow from "../../images/enduser/right-arrow.svg";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  return (
    <div
      className="nav flex-column nav-pills"
      id="profile-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <NavLink to="/account-details" className="nav-link">
        <img src={rightArrow} className="invisible mr-2"></img> Account Details
      </NavLink>
      <NavLink to="/invoices" className="nav-link">
        <img src={rightArrow} className="invisible mr-2"></img> Invoices
      </NavLink>
      <NavLink to="/edit-account" className="nav-link">
        <img src={rightArrow} className="invisible mr-2"></img> Edit Account
      </NavLink>
      <NavLink to="/change-password" className="nav-link">
        <img src={rightArrow} className="invisible mr-2"></img> Change Password
      </NavLink>
      {/* <NavLink to="/notification-settings"  className="nav-link"><img src={rightArrow} className="invisible mr-2"></img> Notifications Settings</NavLink> */}
      <NavLink to="/unsubscribe" className="nav-link">
        <img src={rightArrow} className="invisible mr-2"></img> Unsubscribe
      </NavLink>

      {/* <a className="nav-link active" id="account-details-tab" data-toggle="pill" href="#account-details" role="tab" aria-controls="account-details" aria-selected="true"><img src={rightArrow} className="invisible mr-2" alt="navicon" /> Account Details</a> */}
      {/* <a className="nav-link" id="edit-account-tab" data-toggle="pill" href="#edit-account" role="tab" aria-controls="edit-account" aria-selected="false"><img src={rightArrow} className="invisible mr-2" /> Edit Account</a>
            <a className="nav-link" id="change-password-tab" data-toggle="pill" href="#change-password" role="tab" aria-controls="change-password" aria-selected="false"><img src={rightArrow} className="invisible mr-2" /> Change Password</a>
            <a className="nav-link" id="notifications-settings-tab" data-toggle="pill" href="#notifications-settings" role="tab" aria-controls="notifications-settings" aria-selected="false"><img src={rightArrow} className="invisible mr-2" /> Notifications Settings</a>
          */}
    </div>
  );
}
