import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import adminImg from "../../images/dashboard/admin-img.png";
import { userInfo, updateAccount } from "../../helper/service";
import Loader from "../Loader";
import SuccessPopup from "./SuccessPopup";
import { useAuth } from "../../contexts/auth";

export default function EditAccount() {
  const [isLoader, setIsLoader] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(adminImg);
  const [img, setImg] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      updateUserData(user);
    } else {
      const userId = localStorage.getItem("user_id");
      userInfo(userId).then((data) => {
        updateUserData(data.result.validUser);
      });
    }
  }, []);

  const updateUserData = (user) => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPhone(user.mobile);
    setPhoto(user.image);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img2 = URL.createObjectURL(event.target.files[0]);
      let img = event.target.files[0];
      setImg(img);
      setPhoto(img2);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoader(true);
    setisSuccess(false);
    let data = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      img: img,
    };
    
    updateAccount(data).then((response) => {
      if (response.status === 200 && response.data.result.updateUser) {
        setIsLoader(false);
        setisSuccess(true);
      }
    });
  };

  return (
    <div className="wrapper">
      {isLoader ? <Loader /> : <></>}
      {isSuccess ? (
        <SuccessPopup message="Account details updated successfully" />
      ) : (
        <></>
      )}
      <Header />
      <div className="content-wrapper">
        <section className="profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                <div className="tab-content" id="profile-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="edit-account"
                    role="tabpanel"
                    aria-labelledby="edit-account-tab"
                  >
                    <form name="form" onSubmit={submitForm}>
                      <h2 className="title">Edit Account</h2>
                      <div className="d-flex align-items-center flex-column mb-3">
                        <img src={photo} className="user-img" />
                        <label htmlFor="choose-file">
                          <i
                            className="fas fa-pencil-alt"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </label>
                        <input
                          type="file"
                          id="choose-file"
                          style={{ opacity: "0" }}
                          name="file"
                          onChange={onImageChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          onChange={(event) => {
                            setFirstName(event.target.value);
                          }}
                          value={firstName}
                        />
                      </div>

                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          onChange={(event) => {
                            setLastName(event.target.value);
                          }}
                          value={lastName}
                        />
                      </div>

                      <div className="form-group">
                        <label>Contact no.</label>
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          onChange={(event) => {
                            setPhone(event.target.value);
                          }}
                          value={phone}
                        />
                      </div>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={email}
                          readOnly
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-wrap flex-sm-nowrap">
                        <button type="submit" className="btn btn-blue">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
