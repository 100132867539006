import { useQuery } from "@tanstack/react-query";
import { type } from "os";
import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import file from "../../images/admin/file.svg";
import { getContentByFolderId } from "../../helper/service";
import folderSVG from "../../images/admin/folder-sm.svg";
import close from "../../images/dashboard/modal-close.svg";
import {
  IFolder,
  IgetContentByFolderIdResponse,
} from "../../interfaces/interfaces";
import ContentListByFolder from "./ContentListByFolder";
import goback from "../../images/dashboard/go-back.svg";

type FoldersListModalProps = {
  open: boolean;
  parentFolder: IFolder;
  setParentFolder: React.Dispatch<React.SetStateAction<IFolder | null>>;
  setShowFoldersListModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function FoldersListModal({
  open,
  parentFolder,
  setParentFolder,
  setShowFoldersListModal,
}: FoldersListModalProps) {
  const [selectedFolderId, setSelectedFolderId] = React.useState(
    parentFolder?._id
  );
  const [prevParentFolder, setPrevParentFolder] =
    React.useState<IFolder | null>(null);
  const getContentByFolderIdQuery = useQuery<IgetContentByFolderIdResponse>({
    queryKey: ["getContentByFolderId", selectedFolderId],
    queryFn: () => {
      return getContentByFolderId(selectedFolderId);
    },
  });

  useEffect(() => {
    setSelectedFolderId(parentFolder?._id);
  }, [parentFolder]);

  const handleSubFolderClick = (folder: IFolder) => {
    changeParentFolder(folder);
  };

  const handleGoBackClick = () => {
    if (prevParentFolder) {
      changeParentFolder(prevParentFolder);
    }
  };

  const changeParentFolder = (folder: IFolder) => {
    setPrevParentFolder(parentFolder);
    setParentFolder(folder);
  };

  if (getContentByFolderIdQuery.isLoading)
    return <Spinner animation="border" />;

  return (
    <Modal
      className="insideFolderModal"
      id="insideFolderModal"
      show={open}
      onHide={() => setShowFoldersListModal(false)}
      backdrop="static"
    >
      <Modal.Header className="align-items-center border-0 pb-0">
        <div className="go-back">
          {parentFolder?.parentId !== "0" && (
            <img
              src={goback}
              className="img-fluid mr-3"
              alt="back"
              onClick={handleGoBackClick}
            />
          )}
          <span>Folders List</span>
        </div>
        {/* <div className="go-back">Folders List</div> */}
        <button
          onClick={() => setShowFoldersListModal(false)}
          type="button"
          className="close ml-5"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src={close} />
          </span>
        </button>
      </Modal.Header>

      <div className="folder">
        <div
          className={`card ${
            selectedFolderId === parentFolder?._id ? "active" : ""
          }`}
          // id={this.state.selectedParentFolder}
          // onClick={this.selChildFolder.bind(this, this.state.objs)}
        >
          <div className="card-body">
            <div className="row no-gutters">
              <div className="col-2">
                <img
                  src={parentFolder.image}
                  className="img-fluid mr-2"
                  alt="book"
                  height="75"
                  width="75"
                />
              </div>
              <div className="col-10 pl-2 pl-md-4">
                <h5 className="card-title">{parentFolder.title}</h5>
                <p className="card-text">{parentFolder.subtitle}</p>
                <div className="row">
                  <div className="col">
                    <div className="page-info">
                      <img src={folderSVG} />
                      {parentFolder?.totalsubfolder && (
                        <>
                          {parentFolder.totalsubfolder}{" "}
                          {parentFolder.totalsubfolder > 1
                            ? "Sub Folders"
                            : "Sub Folder"}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getContentByFolderIdQuery.data?.result?.subfolders &&
          getContentByFolderIdQuery.data?.result?.subfolders?.length > 0 && (
            <>
              <h3 className="title">Select Sub Folder</h3>
              <div className="scroll">
                {getContentByFolderIdQuery.data?.result?.subfolders.map(
                  (folder) => {
                    return (
                      <div
                        className="card"
                        id={folder._id}
                        onClick={() => handleSubFolderClick(folder)}
                        key={folder._id}
                      >
                        <div className="card-body">
                          <div className="row no-gutters">
                            <div className="col-2">
                              <img
                                src={folder.image}
                                className="img-fluid mr-2"
                                alt="book"
                                height="75"
                                width="75"
                              />
                            </div>
                            <div className="col-10 pl-2 pl-md-4">
                              <h5 className="card-title">{folder.title}</h5>
                              <p className="card-text">{folder.subtitle}</p>
                              <div className="row">
                                <div className="col">
                                  <div className="page-info">
                                    <img src={file} />
                                    {folder.totalcontent && (
                                      <>
                                        {folder.totalcontent}{" "}
                                        {folder.totalcontent > 1
                                          ? "Contents"
                                          : "Content"}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
        {/* {!this.state.isSub ? <h3 className="title">Select Sub Folder</h3> : ""} */}
        {/* <div className="scroll">{this.state.subfoldersdatalist}</div> */}
        <div className="scroll">
          {/* {this.state.listvideo} */}
          {/* {this.state.listcontent} */}
          <ContentListByFolder folderId={selectedFolderId} />
        </div>
      </div>
    </Modal>
  );
}

export default FoldersListModal;
