import React, { useState } from "react";
import placeholder from "../../images/placeholder.jpg";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import defaultImg from "../../images/avatar.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { monthNames } from "../../helper/constants";
import { editEventCommentByUserId } from "../../helper/service";
import { Modal, Button } from "react-bootstrap";
export default function EventComments({
  comments,
  handleDelete,
  eventId,
  handleEdit,
}) {
  const currentUserId = localStorage.getItem("user_id");
  const [editedComment, setEditedComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleDeleteClick = (commentId) => {
    handleDelete(commentId, eventId);
  };
  const handleEditClick = (commentId, commentText) => {
    setEditingCommentId(commentId);
    setEditedComment(commentText);
    setIsOpen(true);
  };
  const saveComment = (commentId) => {
    if (editedComment.trim()) {
      handleEdit(commentId, editedComment);
      setEditedComment("");
      setEditingCommentId(null);
      setIsOpen(false);
    } else {
      console.error("Cannot save an empty comment");
    }
  };
  const cancelEdit = () => {
    setEditingCommentId(null);
    setEditedComment("");
    setIsOpen(false);
  };
  return (
    comments?.length > 0 && (
      <div className="event-comments">
        <h4 className="title">Comments</h4>
        <div className="comment-list">
          {comments.map(function (sub, key) {
            var fname = "";
            var lname = "";
            var img = placeholder;
            if (sub.subadminId) {
              fname = sub.subadminId.firstName;
              lname = sub.subadminId.lastName;
              if (
                sub.subadminId.company[0].logo &&
                sub.subadminId.company.length > 0
              ) {
                img = sub.subadminId.company[0].logo;
              } else if (sub.subadminId.image) {
                img = sub.subadminId.image;
              } else {
                img = defaultImg;
              }
            } else {
              fname = sub.userId.firstName;
              lname = sub.userId.lastName;
              if (!sub.userId.image) {
                img = defaultImg;
              } else {
                img = sub.userId.image;
              }
            }
            var d1 = new Date(sub.createdAt);

            var months1 = parseInt(d1.getUTCMonth());
            var schedule1 =
              d1.getUTCDate() +
              "-" +
              monthNames[months1] +
              "-" +
              d1.getUTCFullYear();
            var times1 = d1.getUTCHours() + ":" + d1.getUTCMinutes();

            var times1 =
              (d1.getHours() < 10 ? "0" : "") +
              d1.getHours() +
              ":" +
              (d1.getMinutes() < 10 ? "0" : "") +
              d1.getMinutes();
            const isEditing = editingCommentId === sub._id;
            return (
              <div className="media" key={key}>
                {!img ? (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="user-img img-fluid mr-2"
                  />
                ) : (
                  <img src={img} className="user-img img-fluid mr-2" />
                )}
                <div className="media-body">
                  {sub.userId && sub.userId._id === currentUserId && (
                    <div className="dropdown dropleft">
                      <i
                        className="fas fa-ellipsis-v"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleEditClick(sub._id, sub.comment);
                          }}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteClick(sub._id);
                          }}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  )}
                  <h5 className="mt-0 comment-name">
                    {fname} {lname}
                  </h5>
                  
                  {isEditing ? (
                    <div className="edit-comment">
                      <input
                        type="text"
                        value={editedComment}
                        onChange={(e) => setEditedComment(e.target.value)}
                        onBlur={() => saveComment(sub._id)}
                        className="form-control"
                      />
                      <span className="date-time">
                    {schedule1} at {times1}
                  </span>
                      <div className="edit-buttons-container">
                        <button
                          onClick={cancelEdit}
                          className="btn btn-cancel"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={saveComment}
                          className="btn btn-save text-white"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div> 
                    <p className="comment-text">{sub.comment}</p>
                    <span className="date-time">
                    {schedule1} at {times1}
                  </span>
                    </div>
                  )}
                  
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}
