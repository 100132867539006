import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { userInfo } from "../../helper/service";
import { useAuth } from "../../contexts/auth";

export default function PrivacyPolicy() {
  const [privacyPolicy, setprivacyPolicy] = useState();
  const { user } = useAuth();
  useEffect(() => {
    let partnerId=localStorage.getItem("subadminId")
    let index;
    if (user) {
      index=user?.subadminsId.findIndex(subadmin=>subadmin?.subadminId?._id==partnerId)
      setprivacyPolicy(user?.subadminsId[index]?.subadminId?.privacyPolicy);
    } else {
      const userId = localStorage.getItem("user_id");
      userInfo(userId).then((data) => {
        index=data?.result?.validUser?.subadminsId.findIndex(subadmin=>subadmin?.subadminId?._id==partnerId)
        setprivacyPolicy(
          data?.result?.validUser?.subadminsId[index]?.subadminId?.privacyPolicy
        );
      });
    }
  }, []);
  return (
    <>
      <Header />
      <div className="content-wrapper admin-social-wall">
        <h2 style={{ margin: "1em 0em 0em 0.5em" }}>Privacy Policy</h2>
        <div style={{ margin: "1em 1em 0em 1em", textAlign: "justify" }}>
          <p dangerouslySetInnerHTML={{ __html: privacyPolicy }}></p>
        </div>
      </div>
    </>
  );
}