import React, { Component, useEffect, useState } from 'react'
import Header from '../common/Header';
import Loader from '../Loader';
import { NavLink, useParams } from 'react-router-dom';
import courseDetails from '../../images/admin/course-detail-img.png';
import editicon from '../../images/admin/edit-icon.svg';
import photo2 from '../../images/admin/elm-general-photo2.png';
import magnifiying from '../../images/admin/magnifiying-glass.svg';
import arrows from '../../images/admin/arrows.svg';
import g68026 from '../../images/admin/Group 68026.svg';
import g68025 from '../../images/admin/Group 68025.svg';
import TopSearchResultComponent from './TopSearchResultComponent';
import ModuleLessons from './ModuleLessons';
import { getModule,getLiveLessonsModules } from '../../helper/service'

const CoursesDetailPage = () => {
  const [moduleData, setModuleData] = useState();
  const [moduleLesson, setModuleLesson] = useState([]);
  const [loader, setLoader] = useState(false);
  const [courseId, setCourseId] = useState();
  const { id } = useParams();
  let userId= localStorage.getItem("subadminId")

  useEffect(() => {
   let userId= localStorage.getItem("subadminId")
    getModule(id).then((module) => {
      console.log(module, "module")
      setCourseId(module.data.result.courseId)
      localStorage.setItem("courseModuleOrder",module.data.result.courseModuleOrder)
      setModuleData(module.data.result)
      // getLiveLessonsModules(userId,module.data.result.courseId,id).then((moduleLesson)=>{
      //   console.log(moduleLesson.result.res,"moduleLesson")
      //   setModuleLesson(moduleLesson.result.res)
      // })
    })
   
  },[])
  return (
    <div className="wrapper">
      <Header />
      {loader ? (<Loader />) : (<></>)}


      <div className="content-wrapper admin-courses">
        <section className>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7">
                  <p style={{cursor:'pointer',color:'gray'}} onClick={()=>{window.location.href=`/course-module/${courseId}`}}>Return to Modules </p>
                  <h2>{moduleData?.title}</h2>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="course-detail">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-3">
                <div className="course-img text-center">
                  <img src={moduleData?.image} className="img-fluid course-detail-img rounded" />
                </div>
              </div>
            </div>
           
           
          </div>
        </section>
        {/* <TopSearchResultComponent /> */}
        <ModuleLessons details={true} userId={userId} courseId={courseId} id={id} />
      </div>
    </div>
  )

}

export default CoursesDetailPage
