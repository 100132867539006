import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import Loader from "../Loader";
import { Row, Col, Form } from "react-bootstrap";
import SuccessPopup from "./SuccessPopup";
import { UnSubscribeNow, userInfo, getUnSubscribe } from "../../helper/service";
import { useAuth } from "../../contexts/auth";
import { commonUtil } from "../../helper/CommonUtils";

const defaultUnsubscribeOption = "6566fc8d8a6c04391838abc2";

export default function Unsubscribe() {
  const [isLoader, setIsLoader] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [unsubscribeId, setUnsubscribeId] = useState("");
  const [comment, setComment] = useState("");
  const [unsubscribeData, setUnsubscribeData] = useState("");
  const [hasValidSubscription, setHasValidSubscription] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    getUnSubscribeData();
    if (user) {
      updateUserData(user);
    } else {
      const userId = localStorage.getItem("user_id");
      userInfo(userId).then((data) => {
        updateUserData(data.result.validUser);
      });
    }
  }, []);

  const updateUserData = (user) => {
    let partnerId=localStorage.getItem("subadminId");
    let ind=user.subadminsId.findIndex(subadmin=>subadmin?.subadminId?._id==partnerId);
    if (user?.subscriptionId[ind]?.subsId?._id && user?.subscriptionId[ind].status!="In-Active") {
      localStorage.setItem(
        "subscriptionId",
        user?.subscriptionId[ind]?.subsId?._id
      );
    }
    setUnsubscribeData(user.unsubscribeDescription);
    if (
      user?.subscriptionId[ind].status !== "Active"
    ) {
      localStorage.setItem(
        "subscriptionId","")
      setHasValidSubscription(false);
    }

    setUnsubscribeId(defaultUnsubscribeOption);
  };

  const unsubs = (idd) => {
    setUnsubscribeId(idd);
  };

  const savenow = async () => {
    setIsLoader(true);
    setisSuccess(false);
    try {
      let res = await UnSubscribeNow(unsubscribeId, comment);
      if(res.data.message=="Member unsubscribed successfully"){
      setisSuccess(true);
      setTimeout(() => {
        commonUtil.reload();
      }, 2000);
}
    } catch (error) {
      console.log(error.response);
    } finally {
      setIsLoader(false);
    }
  };

  const unSubscribeIterate = (customReasons) => {
    if (customReasons && customReasons.length > 0) {
      const uniqueRadioOptions = Array.from(customReasons).map((reason) => (
        <Form.Group key={reason._id}>
          <Form.Check
            type="radio"
            name="reasons"
            label={reason.text}
            onChange={() => unsubs(reason._id)}
          />
        </Form.Group>
      ));
      setUnsubscribeData(uniqueRadioOptions);
    }
  };

  const getUnSubscribeData = () => {
    const userId = localStorage.getItem("user_id");
    let subadminId = localStorage.getItem("subadminId");
    getUnSubscribe(userId, subadminId).then((data) => {
      unSubscribeIterate(data.result[0]["unsubscribeDescription"]);
    });
  };

  const onChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="content-wrapper">
        {isLoader ? <Loader /> : <></>}
        {isSuccess ? (
          <SuccessPopup message="Unsubscribed Successfully" />
        ) : (
          <></>
        )}
        <section className="profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                <div className="tab-content" id="profile-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="unsubscribe"
                    role="tabpanel"
                    aria-labelledby="unsubscribe-tab"
                  >
                    <h2 className="title">Unsubscribe Page</h2>
                    <h4 className="sub-title">Message</h4>
                    {hasValidSubscription ? (
                      <>
                        <h5 className="tab-message">
                          Sorry to see you leave. Please let us know why you
                          want to unsubscribe :
                        </h5>
                        <form>
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Check
                                  type="radio"
                                  name="reasons"
                                  label="I no longer wish to subscribe to this membership."
                                  style={{ fontSize: "15px" }}
                                  onChange={() =>
                                    unsubs("6566fc8d8a6c04391838abc1")
                                  }
                                />
                              </Form.Group>
                              {unsubscribeData}
                              <Form.Group>
                                <Form.Check
                                  type="radio"
                                  name="reasons"
                                  label="Others"
                                  defaultChecked
                                  style={{ fontSize: "15px" }}
                                  onChange={() =>
                                    unsubs(defaultUnsubscribeOption)
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows={8}
                                  placeholder="Type your reason here…"
                                  name="comment"
                                  onChange={onChange}
                                  value={comment}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="col-md-12"></div>
                          <div className="d-flex justify-content-center flex-wrap flex-sm-nowrap">
                            <button
                              type="button"
                              className="btn btn-blue"
                              onClick={() => savenow()}
                            >
                              Unsubscribe
                            </button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <p>
                          Looks like you do not have a valid subscription,
                          please visit{" "}
                          <a href="/account-details">account details</a> to
                          subscribe.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
