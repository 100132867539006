import React, { Component } from 'react';
import $ from 'jquery';
import Header from './common/Header';
import { Modal } from "react-bootstrap";
import cc from '../images/enduser/credit-card.svg';
import cross from '../images/enduser/cross.svg';


const initialState = {
  isOpen: false,
}

export default class Payment extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    $(document).ready(function () {
      $('.paymentSuccessfull').attr('id', 'paymentSuccessfull');
    })

    return (
      <div className="wrapper">
        <Header />


        <Modal show={this.state.isOpen} onHide={this.closeModal} className="paymentSuccessfull" backdrop="static">

          <Modal.Body className="text-center">
            <div className="modal-header border-0 pb-0">
              <button type="button" className="close float-right" onClick={this.closeModal}>
                <span aria-hidden="true">
                  <img src={cross} alt="close" />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <form className="text-center">
                <img src={cc} className="img-fluid mx-auto d-block" alt="payment-successful" style={{ width: 350 }} />
                <h3 className="title">Payment done Successfully</h3>
                <p className="text">It is a long established fact that a reader will be <br />distracted by the readable content of a page when<br /> looking at its layout.</p>
              </form>
            </div>
          </Modal.Body>
        </Modal>


        <div className="content-wrapper">
          <section className="payment-details">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <h2 className="title">Payment <a href="#">Return to Plan details</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="plan">
                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="title">GOLD PLAN</div>
                        <div className="title">Monthly / $125</div>
                      </div>
                      <p className="content">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using. here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                    </div>
                    <div className="tc">
                      <p className="text"><a href="#">Terms and conditions</a> apply
                        <a href="#" className="float-right">View Details</a>
                      </p>
                    </div>
                    <div className="row d-flex justify-content-center card-detail">
                      <div className="col-md-8">
                        <form>
                          <p className="title">All transactions are secured and encypted</p>
                          <label>Card Details</label>
                          <div className="form-group">
                            <input type="text" name className="form-control" placeholder="Card number" />
                          </div>
                          <div className="form-group">
                            <input type="text" name className="form-control" placeholder="Name on card" />
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <input type="text" name className="form-control" placeholder="Expiration date" />
                            </div>
                            <div className="form-group col-md-6">
                              <input type="text" name className="form-control" placeholder="Security Code" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="button" className="btn btn-custom" onClick={this.openModal}>Pay Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
