import React from 'react';
import book1 from "../../images/admin/book.svg";


const Folder = (props) => {
 
  return (         
  <div className="folder">
  <h5 className="folder-title">{props.folder.title} - Folder</h5>
  <div className="row book align-items-center">
    <div className="col-3">
      <img src={props.folder.image} className="img-fluid" />
    </div>
    <div className="col-9">
      <h5 className="book-title">Miss Makeover Intro</h5>
      <p className="book-text">352 Pages</p>
    </div>
  </div>
  <div className="row book align-items-center">
    <div className="col-3">
      <img src={book1} className="img-fluid" />
    </div>
    <div className="col-9">
      <h5 className="book-title">Miss Makeover Intro</h5>
      <p className="book-text">352 Pages</p>
    </div>
  </div>
</div> );
}
 
export default Folder;