import React from "react";
import { IContent } from "../../interfaces/interfaces";
import { Link } from "react-router-dom";

function ContentAsTableRow({ content }: { content: IContent }) {
  const contentUrl = `/view-content?id=${content._id}`;
  const contentTitle = content.propertise?.at(0)?.title;
  // const contentSchedule = content.propertise?.at(0)?.schedule;

  return (
    <tr>
      <td>
        <Link to={contentUrl} target="_blank">
          <img
            src={content.propertise?.at(0)?.cover_image}
            width={54}
            height={54}
            className="rounded-circle"
            alt="cover"
          />
        </Link>
      </td>

      <td>
        <Link to={contentUrl} target="_blank">
          <h3 className="table-title">{contentTitle}</h3>
        </Link>
      </td>


      {/* <td>
        <h3 className="table-title">
          {contentSchedule && format(new Date(contentSchedule), "dd-MMMM-yyyy")}
        </h3>
      </td> */}
    </tr>
  );
}

export default ContentAsTableRow;
