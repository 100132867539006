import React, { useEffect, useState } from "react";
import { createStateContext } from "react-use";
import Header from "../common/Header";
import MacAppLabels from "../common/MacAppLabels";
import Loader from "../Loader";
import RecentCoursesFoldersList from "./RecentCoursesFoldersList";
import TabNav from "./TabNav";
import TabNavContent from "./TabNavContent";
import TopSearchResultComponent from "./TopSearchResultComponent";
import magnifiyingGlass from "../../images/admin/magnifiying-glass.svg";
import AllCoursesFoldersList from "./AllCoursesFoldersList";
import CoursesSection from "./CoursesSection";
import EnrolledCoursesSection from "./EnrolledCoursesSection";
import { userInfo } from '../..//helper/service'
import LiveSalesCourses from "./LiveSalesCourses";
import UserPurchaseCourses from "./UserPurchaseCourses";

type TabNames = "Recent" | "Scheduled" | "Top";
const [useActiveTab, ActiveTabProvider] = createStateContext<TabNames>("Recent");

export { useActiveTab };

function CoursesLibraryPage() {
  const [pageIsLoading, setPageIsLoading] = React.useState(false);
  const subsId = localStorage.getItem('subscriptionId')
 
  return (
    <div className="wrapper">
      <Header />
      {pageIsLoading ? <Loader /> : <></>}
      <div className="content-wrapper admin-courses">
        <section className="">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="title">
                    {MacAppLabels.courses.heading}
                    {/* <a href="#">{MacAppLabels.subHeading}</a> */}
                  </h2>
                </div>
                {/* <div className="col-lg-6">
                                          <button className="btn btn-custom" data-toggle="modal" data-target="#wantToCreateModal" onClick={this.openCoursesModal}>{MacAppLabels.courses.addCourseLabel}</button>
                                      </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* <FolderComponent
          searchNow={this.searchNow}
          searchData={this.searchData}
          folders={this.state.folders}
          getScheduledCourses={this.getScheduledCourses}
          getTopCourses={this.getTopCourses}
          getRecentCourses={this.getRecentCourses}
        /> */}
        {subsId !=""?
          <>
            <EnrolledCoursesSection />

            <CoursesSection />
          </>
          :
          <>
            <UserPurchaseCourses />
            <LiveSalesCourses />
          </>
        }

        {/* <TopSearchResultComponent /> */}
      </div>

      {/* <AddCoursesFolderModal isModalOpen ={this.state.addCourseModalOpen} openCreateCoursesModal={this.openCreateCoursesModal} openChooseLessonWithCourseModal={this.openChooseLessonWithCourseModal} closeModal={this.closeModal} />
                  <CreateCourseModal  isCreateCourseModalOpen ={this.state.isCreateCourseModalOpen} gallery={this.state.gallery} closeModal={this.closeModal} createCourse={this.createCourse} onChange={this.onChange} onImageChange={this.onImageChange} savedata1={this.savedata1} handleCourseDateChange={this.handleCourseDateChange} courseDates={this.state.courseDates} subscriptionListData={this.state.subscriptionListData}/>
                  <ChooseLessonWithCourseModal isOpenChooseLessonWithCourseModal={this.state.isOpenChooseLessonWithCourseModal} opeCreateLessonsModal={this.opeCreateLessonsModal} openCourseListModal={this.openCourseListModal} openAddFileModal={this.openAddFileModal} data={this.state.allCourses} getLessonsByCourseId={this.totalLessonCount} closeModal={this.closeModal} foldersPopup={this.state.foldersPopup} selectedParentFolder={this.state.selectedParentFolder} subscriptionListData={this.state.subscriptionListData}/>
                  <CreateLessonsModal handleNewResourseChange={this.handleNewResourseChange} isCreateLessonsModalOpen ={this.state.isCreateLessonsModalOpen} closeModal={this.closeModal}  onChange={this.onChange} uploadLesson={this.uploadLesson}  addLessonDetails={this.addLessonDetails} openCreateCoursesModal={this.openCreateCoursesModal} openAddFileModal={this.openAddFileModal} goBack={this.goBack} courseOptions={this.state.courseOptions} handleLessonDateChange={this.handleLessonDateChange} dates={this.state.dates} prevLessonsOptions={this.state.prevLessonsOptions} hideCourseOption={this.state.hideCourseOption} subscriptionData={this.state.subscriptionData}/>
                  <CourseLessonAddedSuccessModal isCourseLessonAddedSuccess = {this.state.isCourseLessonAddedSuccess} closeModal={this.closeModal} />
                  <CourseListModal isModalOpen ={this.state.isCourseListModalOpen} closeModal={this.closeModal} openChooseLessonWithCourseModal={this.openChooseLessonWithCourseModal} opeCreateLessonsModal={this.opeCreateLessonsModal}  lessonData={this.state.subfoldersdata} parentDescription={this.state.parentDescription} parentTitle = {this.state.parentTitle} parentImage={this.state.parentFolderImage}/>
                  <AddFileModal isFileModalOpen={this.state.isFileModalOpen} opeCreateLessonsModal={this.opeCreateLessonsModal} uploadLesson={this.uploadLesson} selectedTitle={this.state.selectedTitle}  closeModal={this.closeModal} openCoursesModal={this.openCoursesModal} handleFileChange={this.handleFileChange} selectedFile={this.state.selectedFiles}/>
             */}

      {/* {console.log(this.state.allCourses, "allcourse")} */}
    </div>
  );
}

export default CoursesLibraryPage;
