import React, { useEffect, useState } from "react";
import PurchaseCourses from "./PurchaseCourses";
import magnifiyingGlass from "../../images/admin/magnifiying-glass.svg";
import searchDark from '../../images/enduser/search-dark.svg';
import { userInfo } from '../..//helper/service'

function UserPurchaseCourses() {
  const [searchPurchaseCour,setSearchPurchaseCour]=useState("");
  return (
    <section className="courses-folders">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex items-content-center">
            <h5 className="fs-6 ">Your Courses</h5>
            <hr className="flex-fill ml-4" />
          </div>
          <div className="col-4">
            <div className="search-course">
              <input
                type="text"
                className="form-control"
                placeholder="Search Course"
                name="searchPurchaseCour"
                value={searchPurchaseCour}
                onChange={(e)=>setSearchPurchaseCour(e.target.value)}
                
              />
              <span className="search-icon">
                <img src={searchDark} alt="Search" />
              </span>
            </div>
          </div>
          
            <div className="col-md-12">
              <PurchaseCourses searchPurchaseCour={searchPurchaseCour}/>
            </div>
          
        </div>
      </div>
    </section>
  );
}

export default UserPurchaseCourses;
