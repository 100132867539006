export default class MacAppLabels {
    static recentlyAdded= "Recently Added";
    static scheduledCourses= "Scheduled Courses";
    static topCourses= "Top Courses";
    static viewAll= "View All";
    static subHeading = "Return to Main Library";
    static courses = {
        heading : "Courses",
        addCourseLabel : "+ Add Courses",


    };
    static challenges ={
        heading : "Challenges",
        addChallengesLabel : "+ Add Challenges",
    }
}