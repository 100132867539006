import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Navbar, Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckoutRegistration from "./CheckoutRegistration";
import CheckoutPayment from "./CheckoutPayment";
import Loader from "../Loader";

import { subadminInfo } from "../../helper/service";

const CheckoutWithRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    subscriptionId,
    subscriptionFrequency,
    subadminId,
    courseId,
    courseFrequency,
    eventId,
    eventFrequency,
  } = useParams();
  const [showPayment, setShowPayment] = useState(false);
  const [stripeOptions, setStripeOptions] = useState({});
  const [stripePromise, setstripePromise] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [membershipName, setMembershipName] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [brandColor, setBrandColor] = useState("");
  const [memberDetails, setMemberDetails] = useState();
  const [heading, setHeading] = useState();
  const [hasError, setHasError] = useState(false);
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    if (subscriptionId) {
      setHeading("YOU ARE NOW PAYING TO SUBSCRIBE TO A PAID PLAN");
      if (
        !subscriptionId ||
        subscriptionId === undefined ||
        !subscriptionFrequency ||
        subscriptionFrequency === undefined
      )
        return;
    }
    else if (courseId) {
      setHeading("YOU ARE NOW PAYING TO PURCHASE A COURSE");
      if (
        !courseId ||
        courseId === undefined ||
        !courseFrequency ||
        courseFrequency === undefined
      )
        return;
    }
    else if (eventId) {
      setHeading("YOU ARE NOW PAYING TO PURCHASE A EVENT");
      console.log(eventId, eventFrequency, "event details")
      if (
        !eventId ||
        eventId === undefined ||
        !eventFrequency ||
        eventFrequency === undefined
      )
        return;
    }

    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    const data = await subadminInfo(subadminId);
    if (data) {

      setEmail(data.result.validUser.email);
      setPhone(data.result.validUser.phoneNumber);
      setBrandColor(data.result.validUser.brandColor);
      setLogo(data.result.validUser.logo);
      setMembershipName(data.result.validUser.membershipName);
      setCompanyName(data.result.validUser.company[0].name);
    }
    setIsLoading(false);
  };

  const startPayment = async (data, memberData) => {
    const ops = {
      clientSecret: data.clientSecret,
    };

    setStripeOptions(ops);
    setstripePromise(loadStripe(data.publishableKey));
    if (memberData.couponData) 
      setCoupon(memberData.couponData?.couponCode || "");
    setShowPayment(true);
    setMemberDetails(memberData);
  };

  const updateHeading = async (headingData) => {
    setHeading(headingData);
  };

  const setError = async (headingData) => {
    setHeading(headingData);
    setHasError(true);
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Navbar
        expand="lg"
        className="sales-navbar"
        style={{ backgroundColor: brandColor }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
        >
          <Navbar.Brand href="#">
            <img
              src={logo}
              alt="Company Logo"
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Brand href="#">
            <div>
              <div style={{ color: "white" }}>{companyName}</div>
              <div style={{ color: "white" }}>{membershipName}</div>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
        </div>
      </Navbar>
      <div className="content-wrapper admin-social-wall">
        <h3 style={{ margin: "1em 0em 0.5em 0.5em" }}>Checkout</h3>
        <p style={{ marginLeft: "1em" }}>{heading}</p>
        {!hasError ? (
          <Container>
            {showPayment ? (
              <Elements stripe={stripePromise} options={stripeOptions}>
                {subscriptionId &&
                  <CheckoutPayment
                    subscriptionId={subscriptionId}
                    subscriptionFrequency={subscriptionFrequency}
                    memberDetails={memberDetails}
                    coupon={coupon}
                  />
                }
                {courseId && <CheckoutPayment
                  courseId={courseId}
                  courseFrequency={courseFrequency}
                  memberDetails={memberDetails}
                  coupon={coupon}
                />
                }
                {eventId && <CheckoutPayment
                  eventId={eventId}
                  eventFrequency={eventFrequency}
                  memberDetails={memberDetails}
                  coupon={coupon}
                />
                }
              </Elements>
            ) : (
              <CheckoutRegistration
                subscriptionId={subscriptionId}
                subscriptionFrequency={subscriptionFrequency}
                updateHeadingHandler={updateHeading}
                startPaymentHandler={startPayment}
                errorHandler={setError}
              />
            )}
          </Container>
        ) : (
          <button
            className="btn btn-primary"
            style={{
              backgroundColor: brandColor,
              border: "none",
              marginLeft: "1em",
            }}
            onClick={() => window.history.back()}
          >
            Go Back
          </button>
        )}
      </div>
      <footer className="full-screen-footer mt-3">
        <Container fluid style={{ backgroundColor: brandColor }}>
          <Row className="justify-content-center">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center justify-content-lg-between"
            >
              <div className="column-content ml-5">
                <p
                  style={{
                    color: "white",
                    marginTop: "0.5em",
                    fontSize: "1.2rem",
                  }}
                >
                  {companyName}
                </p>
                <p style={{ color: "white", fontSize: "0.7rem" }}>
                  <a href="/terms-and-conditions" style={{ color: "white" }}>
                    {" "}
                    CLICK HERE FOR TERMS AND CONDITIONS{" "}
                  </a>
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center justify-content-lg-end"
            >
              <div className="column-content">
                <p
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginTop: "1.5rem",
                  }}
                >
                  PHONE: {phone}
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginBottom: "0rem",
                  }}
                >
                  EMAIL: {email}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default CheckoutWithRegistration;
