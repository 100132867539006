import React, { useCallback, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import grid from "../../images/admin/Group 69383.svg";
import list from "../../images/admin/Group 68030.svg";
import calenderSort from "../../images/admin/calendar (8).svg";
import {
  IContent,
  IgetContentByFolderIdResponse,
} from "../../interfaces/interfaces";
import { useQuery } from "@tanstack/react-query";
import { getContentByFolderId } from "../../helper/service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import infoIcon from "../../images/dashboard/ic_error.svg";
import magnifiyingGlass from "../../images/admin/magnifiying-glass.svg";
import searchDark from "../../images/enduser/search-dark.svg";
import defaultFolderImage from "../../images/admin/folder-sm.svg";
import Loader from "../Loader";
import ContentAsTableRow from "./ContentAsTableRow";
import goback from "../../images/dashboard/go-back.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

function FolderDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [BrandColor, setBrandColor] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    const brandColor = localStorage.getItem("subadminBrandColor");
    if (brandColor) {
      setBrandColor(brandColor);
    }
  }, []);
  const getContentByFolderIdQuery = useQuery<IgetContentByFolderIdResponse>({
    queryKey: ["getContentByFolderId", id],
    queryFn: () => {
      return getContentByFolderId(id);
    },
  });
  const [contentSearchQuery, setContentSearchQuery] = React.useState("");

  const folderDetail = getContentByFolderIdQuery.data?.result;
  let folderSchedule=folderDetail?.folderSchedule || new Date();
  if(new Date()<new Date(folderSchedule)){
    navigate("/not-found")
  }
  const folderTotalContent = getContentByFolderIdQuery.data?.result?.content?.length;
  const subFoldersIsAvailable =
    getContentByFolderIdQuery.isSuccess &&
    getContentByFolderIdQuery.data?.result?.subfolders &&
    Array.isArray(getContentByFolderIdQuery.data?.result?.subfolders) &&
    getContentByFolderIdQuery.data?.result?.subfolders.length > 0;
  const contentAvailable =
    getContentByFolderIdQuery.isSuccess &&
    getContentByFolderIdQuery.data?.result &&
    Array.isArray(getContentByFolderIdQuery.data?.result?.content) &&
    getContentByFolderIdQuery.data?.result?.content.length > 0;

  const totalSubFolders = getContentByFolderIdQuery.data?.result?.subfolders
    ? getContentByFolderIdQuery.data?.result?.subfolders.filter(
        (x) => x.totalcontent && x.totalcontent > 0 && !x.schedule
      ).length || 0
    : 0;

  const getPublishedPages = useCallback(() => {
    if (!contentAvailable) return [];
    return getContentByFolderIdQuery.data?.result?.content?.filter(
      (content) => {
        if (!content || !content.propertise || !content.propertise?.at(0))
          return false;

        const contentSchedule = new Date(
          content.propertise?.at(0)?.schedule as string
        );
        const now = new Date();
        return contentSchedule < now;
      }
    );
  }, [contentAvailable, getContentByFolderIdQuery.data?.result?.content]);

  const getScheduledPages = useCallback(() => {
    if (!contentAvailable) return [];
    return getContentByFolderIdQuery.data?.result?.content?.filter(
      (content) => {
        if (
          !content ||
          !content.propertise ||
          !content.propertise?.at(0) ||
          !content.propertise?.at(0)?.schedule
        )
          return false;

        const contentSchedule = new Date(
          content.propertise?.at(0)?.schedule as string
        );
        const now = new Date();
        return contentSchedule > now;
      }
    );
  }, [contentAvailable, getContentByFolderIdQuery.data?.result?.content]);

  const contentSearchFilter = useCallback(
    (contents: IContent[] | undefined) => {
      if (!contentSearchQuery) return contents;
      if (!contents) return [];
      return contents.filter((content) => {
        if (!content || !content.propertise || !content.propertise?.at(0))
          return false;
        const contentTitle = content.propertise?.at(0)?.title;
        return contentTitle
          ?.toLowerCase()
          .includes(contentSearchQuery.toLowerCase());
      });
    },
    [contentSearchQuery]
  );

  return (
    <div className="wrapper">
      <Header />
      {getContentByFolderIdQuery.isLoading && <Loader />}

      <div className="content-wrapper admin-content-library">
        <section>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 d-flex">
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    <img src={goback} className="img-fluid mr-3" />
                  </Link>
                  <h2 className="title">
                    {getContentByFolderIdQuery.data?.result?.folderTitle &&
                      getContentByFolderIdQuery.data?.result?.folderTitle}
                    <span className="ml-4">
                      {/* <Link to="/content-library">Return to Content Library</Link> */}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-folders">
          <div className="container-fluid">
            {totalSubFolders > 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="title">
                    Content Sub Folders{" "}
                    <small className="ml-2">
                      {totalSubFolders} Sub{" "}
                      {totalSubFolders > 1 ? "Folders" : "Folder"}
                    </small>
                  </h2>
                </div>
              </div>
            ):(
              folderDetail?.folderImage && (
                <div className="row mt-5">
                  <div className="col-lg-7 col-md-7 ml-2 d-flex justify-content-left align-items-end flex-wrap">
                    <div className="mt-2">
                      <img src={folderDetail?.folderImage} alt={folderDetail?.folderTitle} width={200} height={200}/>
                      <h2 className="fs-5">{folderDetail?.folderTitle}</h2>
                    </div>
                  
                  <div className="d-flex flex-column align-items-cente">
                  
                  <span className="mb-2 ml-2 fw-lighter text-secondary">{folderDetail?.content?.length} Page</span>
                  </div>
                  </div>
                </div>
              )
            )

            }

            {/* <div className="row">
              <h2 className="title">{folderDetail?.folderTitle}</h2>
               <img src={folderDetail?.content[0]?.image} className="img-fluid" />
            </div> */}

            {/* <div className="row flex-wrap">
              <div className="col-xl-12">
                <div className="d-flex justify-content-left align-items-center flex-wrap">
                  <label>Sort by :</label>
                  <select className="form-control mx-3 sort">
                    <option> Name </option>
                  </select>
                  <img src={grid} className="mx-3" alt="grid" />
                  <img src={list} className="mx-3" alt="list" />
                  <img src={calenderSort} className="mx-3" alt="calender" />
                  <input
                    type="text"
                    className="form-control mx-3 date"
                    placeholder="21 - Jan - 2020"
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12">
                <div className="content-slider">
                  <Swiper
                    spaceBetween={5}
                    slidesPerView={6}
                    modules={[Navigation, Mousewheel]}
                    navigation={true}
                    className="d-flex justify-content-left flex-wrap scroll"
                    breakpoints={{
                      400: {
                        width: 400,
                        slidesPerView: 2,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {subFoldersIsAvailable &&
                      getContentByFolderIdQuery.data?.result?.subfolders?.map(
                        (folder) => {
                          let folderImage = folder?.image;
                          const totalContent = folder?.totalcontent || 0;
                          const folderUrl = `/folder/${folder._id}`;
                          const folderSchedule = folder?.schedule;
                          let folderScheduleDate = "";
                          let formattedCurrentDateTime = "";
                          if (folderSchedule) {
                            var originalDate = new Date(folderSchedule);
                            var selectedTimeZone = localStorage.getItem("websiteTimeZone");
                            if (selectedTimeZone) {
                              folderScheduleDate = originalDate.toLocaleString("en-US", {
                                timeZone: selectedTimeZone,
                              });
                  
                              var currentDateTime = new Date();
                              formattedCurrentDateTime = currentDateTime.toLocaleString("en-US", {
                                timeZone: selectedTimeZone,
                              });
                            }
                          }

                          if (!folderImage) {
                            folderImage = defaultFolderImage;
                          }
                          if (folder.totalcontent !== 0 && (!folderSchedule || new Date(folderScheduleDate) < new Date(formattedCurrentDateTime)))
                           {
                            return (
                              <SwiperSlide key={folder._id}>
                                {folder.title && (
                                  <div
                                    className="card highlight-card cursor-pointer"
                                    onClick={() => {
                                      navigate(folderUrl);
                                    }}
                                  >
                                    <div className="rounded">
                                      <img
                                        src={folderImage}
                                        className="img-over w-100 cursor-pointer"
                                        alt={folder.title}
                                        onError={(e) => {
                                          (e.target as HTMLImageElement).src =
                                            defaultFolderImage;
                                        }}
                                      />
                                    </div>
                                    <h5 className="slide-title pl-2">
                                      {folder.title.length > 20
                                        ? `${folder.title.substring(0, 20)}...`
                                        : folder.title}
                                    </h5>
                                    <p className="slide-text pl-2">
                                      {totalContent}{" "}
                                      {totalContent > 1 ? "Pages" : "Page"}
                                    </p>
                                  </div>
                                )}
                              </SwiperSlide>
                            );
                          }
                        }
                      )}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-tabs"
                  id="contentLibraryTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="pages-tab"
                      data-toggle="tab"
                      href="#pages"
                      role="tab"
                      aria-controls="pages"
                      aria-selected="true"
                    >
                      Pages
                    </a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="scheduledPages-tab"
                      data-toggle="tab"
                      href="#scheduledPages"
                      role="tab"
                      aria-controls="scheduledPages"
                      aria-selected="false"
                    >
                      Scheduled Pages
                    </a>
                  </li> */}
                  <li className="ml-auto">
                    <div className=" search-content">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Content Library"
                        onChange={(e) => {
                          setContentSearchQuery(e.target.value);
                        }}
                      />
                      <span className="search-icon">
                        <img src={searchDark} alt="search icon" />
                      </span>
                    </div>
                  </li>
                </ul>
                <div className="filter content-folders mb-0 mt-4">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-4 col-md-5">
                      <div className="d-flex align-items-center">
                        {/* <label>Sort by :</label>
                            <select className="form-control sort">
                              <option> Name </option>
                            </select> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      {/* <h5>{this.state.folderName}</h5> */}
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <span className="d-flex align-items-end">
                        {/* <img src={preview} alt="preview" className='pointer'/>
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip-left">
                                  Information
                                </Tooltip>
                              }
                            >
                              <img
                                src={infoIcon}
                                className="float-right pointer"
                                width={17}
                                height={17}
                              /> 
                              </OverlayTrigger>
                              */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="contentLibraryTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pages"
                    role="tabpanel"
                    aria-labelledby="pages-tab"
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip id="tooltip-left">Information</Tooltip>}
                    >
                      <img
                        src={infoIcon}
                        className="float-right pointer"
                        width={17}
                        height={17}
                        alt="Info"
                      />
                    </OverlayTrigger>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <tbody>
                          {/* {this.state.pagesContent} */}
                          {!contentAvailable && <p>No Content Available</p>}
                          {contentAvailable &&
                            contentSearchFilter(getPublishedPages())?.map(
                              (content) => {
                                return <ContentAsTableRow content={content} />;
                              }
                            )}
                          {contentAvailable &&
                            getPublishedPages()?.length === 0 && (
                              <p>No Published Content Available</p>
                            )}
                          {contentAvailable &&
                            contentSearchQuery &&
                            contentSearchFilter(getPublishedPages())?.length ===
                              0 && (
                              <p>
                                No Content Found for "
                                <i className="text-danger">
                                  {contentSearchQuery}
                                </i>
                                "
                              </p>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="scheduledPages"
                    role="tabpanel"
                    aria-labelledby="scheduledPages-tab"
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip id="tooltip-left">Information</Tooltip>}
                    >
                      <img
                        src={infoIcon}
                        alt="info"
                        className="float-right pointer"
                        width={17}
                        height={17}
                      />
                    </OverlayTrigger>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <tbody>
                          {/* {this.state.scheduledContent} */}
                          {!contentAvailable && <p>No Content Available</p>}
                          {contentAvailable &&
                            contentSearchFilter(getScheduledPages())?.map(
                              (content) => {
                                return (
                                  <ContentAsTableRow
                                    key={content._id}
                                    content={content}
                                  />
                                );
                              }
                            )}
                          {contentAvailable &&
                            getScheduledPages()?.length === 0 && (
                              <p>No Scheduled Content Available</p>
                            )}
                          {contentAvailable &&
                            contentSearchQuery &&
                            contentSearchFilter(getScheduledPages())?.length ===
                              0 && (
                              <p>
                                No Content Found for "
                                <i className="text-danger">
                                  {contentSearchQuery}
                                </i>
                                "
                              </p>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default FolderDetailPage;
