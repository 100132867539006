import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import doc from "../../../images/admin/doc.png";

export default function TextMediaText(props) {
  const [containerData, setContainerData] = useState("");

  useEffect(() => {
    var i = 0;
    const containerData = props.containers.map(function (subs) {
      i++;
      if (i <= 3) {
        if (subs.order == 2) {
          var filename = subs?.media?.url?.substring(
            subs?.media?.url?.lastIndexOf("/") + 1
          );
          var ext = filename?.substr(filename?.lastIndexOf(".") + 1);
          var paths = "";
          var isDoc = false;
          if (ext == "pdf" || ext == "doc" || ext == "docx"|| ext == "xlsx" || ext == "csv" || ext == "txt") {
            paths = doc;
            isDoc = true;
          } else {
            paths = subs.media.url;
          }
          if (ext == "mkv" || ext == "webm" || ext == "mov" ||ext == "mp4") {
            return (
              <div className="col-md-4 text-center">
                <p className="text">
                  {subs.media.mediaTitle !== "undefined"
                    ? subs.media.mediaTitle
                    : ""}
                </p>
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  url={paths}
                  controls={true}
                  playing={false}
                  type="video/mov"
                  width="423"
                  height="353"
                />
              </div>
            );
          } else if (ext == "mp3" || ext == "ogg" ||ext == "wav") {
            return (
              <div className="col-md-4 text-center">
                <p className="text">
                  {subs.media.mediaTitle !== "undefined"
                    ? subs.media.mediaTitle
                    : ""}
                </p>
                <div className="audio-player-wrapper">
                  <ReactAudioPlayer src={paths} controls={true} />
                </div>
              </div>
            );
          } else {
            return (
              <div className="col-md-4 text-center">
                <p className="text">
                  {subs.media.mediaTitle !== "undefined"
                    ? subs.media.mediaTitle
                    : ""}
                </p>
                <img src={paths} width={"100%"} className="img-fluid" />
                {isDoc ? (
                  <p>
                    <a target="_blank" href={subs.media.url}>
                      Download
                    </a>
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          }
        } else {
          return (
            <div className="col-md-4">
              <p
                className="text"
                dangerouslySetInnerHTML={{
                  __html: subs.text.bodyText ? subs?.text?.bodyText : "",
                }}
              ></p>
            </div>
          );
        }
      }
    });

    setContainerData(containerData);
  }, [props.containers]);
  
  return (
    <div className="template">
      <div className="row">{containerData}</div>
    </div>
  );
}
