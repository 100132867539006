import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Navbar } from "react-bootstrap";
import { userInfo, subadminInfo } from "../../helper/service";
import { useAuth } from "../../contexts/auth";

export default function TermsAndConditions() {
  const [termsConditions, settermsConditions] = useState();
  const [showHeader, setShowHeader] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [membershipName, setMembershipName] = useState();
  const [logo, setLogo] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const subadminId = new URLSearchParams(window.location.search).get(
      "subadminId"
    );
    if (subadminId) {
      const data = await subadminInfo(subadminId);
      if (data) {
        settermsConditions(data.result.validUser.termsCondition);
        setLogo(data.result.validUser.logo);
        setMembershipName(data.result.validUser.membershipName);
        setCompanyName(data.result.validUser.company[0].name);
      }
    } else {
      let partnerId=localStorage.getItem("subadminId");
      let partnerIndex;
      setShowHeader(true);
      if (user) {
        partnerIndex=user.subadminsId.findIndex(part=>part.subadminId._id==partnerId)
        settermsConditions(
          user?.subadminsId[partnerIndex]?.subadminId?.termsCondition
        );
      } else {
        
        const userId = localStorage.getItem("user_id");
        userInfo(userId).then((data) => {
          partnerIndex=data?.result?.validUser?.subadminsId.findIndex(part=>part.subadminId._id==partnerId)
          settermsConditions(
            data?.result?.validUser?.subadminsId[partnerIndex]?.subadminId?.termsCondition
          );
        });
      }
    }
  };
  return (
    <>
      {showHeader ? (
        <Header />
      ) : (
        <Navbar
          expand="lg"
          className="sales-navbar"
          style={{ backgroundColor: "#3F53A5" }}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
          >
            <Navbar.Brand href="#">
              <img
                src={logo}
                alt="Company Logo"
                width="60"
                height="60"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Brand href="#">
              <div>
                <div style={{ color: "white" }}>{companyName}</div>
                <div style={{ color: "white" }}>{membershipName}</div>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
          </div>
        </Navbar>
      )}
      <div
        className="content-wrapper admin-social-wall"
        style={{ minHeight: "400px" }}
      >
        <h2 style={{ margin: "1em 0em 0em 0.5em" }}>Terms and Conditions</h2>
        <div style={{ margin: "1em 1em 0em 1em", textAlign: "justify" }}>
          <p dangerouslySetInnerHTML={{ __html: termsConditions }}></p>
        </div>
      </div>
    </>
  );
}
