import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback ,useState} from "react";
import { coursecommentlist, saveCoursecomment,deleteCourseLessonCommentByUserId } from "../../helper/service";
import {
  IcoursecommentlistResponse,
  ISaveCommentRequest,
  ICommentByUserID,
} from "../../interfaces/interfaces";
import avatar from "../../images/avatar.jpeg";
import ConfirmationModal from "../Setting/ConfirmationModal";
type LessonCommentSectionProps = {
  lessonId: string;
};

function LessonCommentSection(props: LessonCommentSectionProps) {
  const [showWarningModal,setWarningModal] = useState(false);
  const [isDelete,setDelete] = useState(false);
  const [mdlTitle,setTitle] =useState('');
  const [mdlType,setType] =useState('');
  const [mdlMessage,setMessage] =useState('');
  const [commentData,setCommentData] = useState<ICommentByUserID | null>(null);

  let brandColor = localStorage.getItem("subadminBrandColor");
  const coursecommentlistQuery = useQuery<IcoursecommentlistResponse>({
    queryKey: ["coursecommentlist", props.lessonId],
    queryFn: () => {
      return coursecommentlist(props.lessonId);
    },
  });
  const saveCoursecommentMutation = useMutation({
    mutationKey: ["saveCoursecomment"],
    mutationFn: (data: ISaveCommentRequest) => {
      return saveCoursecomment(data);
    },
    onSuccess: () => {
      coursecommentlistQuery.refetch();
    },
  });
  const deleteCommentMutation = useMutation({
    mutationKey: ["deleteEventComment"],
    mutationFn: (data:ICommentByUserID) => {
      const user = data?.userId?._id;
      const commentId = data?._id;
      return deleteCourseLessonCommentByUserId(lessonId,commentId,user);
    },
    onSuccess: () => {
      coursecommentlistQuery.refetch();
      setType("success");
      setMessage("Comment deleted successfully")
      setTitle("Successfull");
      setWarningModal(true);
      setDelete(false);
      setCommentData(null);
    },
    onError: () => {
      setType("error");
      setMessage("Failed to delete comment")
      setTitle("Error!!!");
      setWarningModal(true);
      setDelete(false);
      setCommentData(null);
    },
  });

  const handleDelete = useCallback(
    (comment: ICommentByUserID) => {
      setCommentData(comment);
      setDelete(true);
      setWarningModal(true);
      setType("delete");
      setMessage("Are you sure you want to delete this comment?");
      setTitle("Delete");
    },
    []
  );
  const confirmDelete = () => {
    if (isDelete && commentData) {
      deleteCommentMutation.mutate(commentData);
    }
  };
  const comments = coursecommentlistQuery.data?.result?.commentByUserId;
  const courseId =
    typeof coursecommentlistQuery.data?.result?.courseId === "string"
      ? coursecommentlistQuery.data?.result?.courseId
      : coursecommentlistQuery.data?.result?.courseId?._id;
  const lessonId = coursecommentlistQuery.data?.result?._id;
  const userId = localStorage.getItem("user_id");
  const [comment, setComment] = React.useState<string>("");
  const saveComment = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // const comment = e.currentTarget.comment.value;

      if (!courseId) {
        alert("courseId missing");
        return;
      }
      if (!lessonId) {
        alert("lessonId missing");
        return;
      }
      if (!userId) {
        alert("userId missing");
        return;
      }
      if (!comment) {
        // alert("comment missing");
        return;
      }

      const data: ISaveCommentRequest = {
        courseId,
        lessonId,
        commentByUserId: {
          userId,
          comment,
        },
      };
      saveCoursecommentMutation.mutate(data, {
        onSuccess: () => {
          coursecommentlistQuery.refetch();
          setComment("");
        },
        onError: () => {
          alert("Failed to save comment");
        },
      });
    },
    [
      comment,
      courseId,
      coursecommentlistQuery,
      lessonId,
      saveCoursecommentMutation,
      userId,
    ]
  );
  const subAdminEmail = localStorage.getItem("subadminEmail");

  return (
    <>
    {showWarningModal && <ConfirmationModal 
      type={mdlType} 
      title={mdlTitle} 
      message={mdlMessage} 
      onConfirm={()=>{
        if(isDelete){
          confirmDelete();
        }
        setWarningModal(false);
        setType("");
        setMessage("");
        setTitle("");
      }} 
      onClose={()=>{
        setDelete(false);
        setCommentData(null);
        setWarningModal(false);
        setType("");
        setMessage("");
        setTitle("");
      }} 
    />}
    <div>
      <a href={`mailto:${subAdminEmail}`} style={{ textDecoration: "none" }}>
        <span
          className="d-flex justify-content-between align-items-center position-relative"
          style={{
            width: "240px",
            color: `${brandColor}`,
            top: "35px",
            marginBottom: "8rem",
          }}
        >
          <span
            className="d-flex border rounded-pill text-sm pl-3 py-2 position-absolute"
            style={{ width: "200px", backgroundColor: "#F5F5F5", left: "0px" }}
          >
            Ask your Instructor
          </span>
          <span
            className="rounded-circle position-absolute"
            style={{
              background: "white",
              right: "0px",
              border: "4px solid white",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="65"
              fill={`${brandColor}`}
              className="bi bi-question-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
            </svg>
          </span>
        </span>

        {/* <img src="/assets/svg/askYourInstructor.svg" alt="ask" /> */}
      </a>

      <h6>Lesson Comments</h6>
      <form onSubmit={saveComment}>
        <div className="form-group">
          <textarea
            className="form-control"
            id="commentTextArea"
            name="comment"
            rows={3}
            placeholder="Share your thoughts"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <button
          type="submit"
          style={{ background: `${brandColor}`,border:'none'}}
          className="btn btn-primary"
        >
          Submit
        </button>
      </form>
      <div className="d-flex flex-column gap-2 mt-4">
        {comments?.map((comment) => {
          if (comment.subadminId === null && comment.userId === null)
            return <></>;
          var isAdmin = comment.subadminId !== null;
          var userName = isAdmin
            ? comment.subadminId?.firstName
            : comment.userId?.firstName;
          var userImage;
          if (comment.subadminId != null) {
            if (comment.subadminId.company && comment.subadminId.company.length > 0 && comment.subadminId.company[0].logo) {
              userImage = comment.subadminId.company[0].logo;
          } else if (comment.subadminId.image) {
              userImage = comment.subadminId.image;
          } 
          } else {
            userImage = comment.userId?.image;
          }
          if (!userImage) {
            userImage = { avatar };
          }

          const commentTime = comment.createdAt
            ? new Date(comment.createdAt)
            : null;
          return (
            <div key={comment._id} className="d-flex w-100 border-bottom py-4">
              
              <div>
                <img
                  className="user-image"
                  src={userImage}
                  alt="person"
                  onError={(e) => {
                    e.currentTarget.src = avatar;
                  }}
                />
              </div>
              
              <div className="flex-fill mx-3">
              {/* <div className="dropdown dropleft">
                        <i
                          className="fas fa-ellipsis-v"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => handleDelete(comment)}
                          >
                            Delete
                          </a>
                        </div>
                      </div> */}
                <h6>{userName}</h6>
                <p>{comment.comment}</p>
              </div>
              
              <div>
                <span>
                  {/* {commentTime?.toLocaleDateString()} @{" "} */}
                  {commentTime?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </span>
                {comment?.userId?._id && comment?.userId?._id === userId &&
                (<div className="dropdown dropleft mt-1">
                  <i
                    className="fas fa-ellipsis-v"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div className="dropdown-menu">
                    {/* <a
                      className="dropdown-item"
                      onClick={() =>""
                        // "handleEditComment(
                        //   res._id,
                        //   sub._id,
                        //   sub.comment,
                        //   sub.userId._id
                        // )"
                      }
                    >
                      Edit
                    </a> */}
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleDelete(comment)
                      }
                    >
                      Delete
                    </a>
                  </div>
                </div>)}
                </div>
              </div>
          );
        })}
      </div>
    </div>
  </>
  );
}

export default LessonCommentSection;
