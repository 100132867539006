import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";
import { login, verify } from "../helper/service";
import bgImage from "../images/body-bg.svg";
import { useAuth } from "../contexts/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [redirectUrl, setRedirectUrl] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrors, setLoginErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [accessCode, setAccessCode] = useState("");
  const [loginError, setLoginError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { loginAction } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl = searchParams.get("redirect");
    setRedirectUrl(redirectUrl);

    const savedEmail = localStorage.getItem("email") || "";
    const savedPassword = localStorage.getItem("password") || "";
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(savedRememberMe);
    }
  }, []);

  const showPassword = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoginError("");

    if (accessCode) {
      verify(accessCode)
        .then((response) => {
          if (response?.success) {
            loginAction(response?.data);
            navigate("/verify");
          } else {
            setLoginError(
              response.data.message || "Invalid access code, please try again."
            );
          }
        })
        .catch((error) => {
          console.log("Error while logging in with access ID", error);
          setLoginError("Invalid access code, please try again.");
        });
    } else {
      if (validate()) {
        let data = {
          email: email,
          password: password,
        };

        login(data)
          .then((result) => {
            if (result?.success) {
              loginAction(result);
              // navigate(
              //   redirectUrl
              //     ? `/membership?redirect=${redirectUrl}`
              //     : "/membership"
              // );
              navigate("/membership");
              if (rememberMe) {
                localStorage.setItem("email", email);
                localStorage.setItem("password", password);
                localStorage.setItem("rememberMe", "true");
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("rememberMe");
              }
            } else {
              setLoginError("Invalid username or password, please try again.");
            }
          })
          .catch((error) => {
            console.log("Error while logging in", error);
            setLoginError("Invalid username or password, please try again.");
          });
      }
    }
  };

  const validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors["email"] = "*Username is required";
    } else {
      errors["email"] = "";
    }

    if (!password) {
      formIsValid = false;
      errors["password"] = "*Password is required";
    } else {
      errors["password"] = "";
    }
    setLoginErrors(errors);

    return formIsValid;
  };

  document.body.style.backgroundImage = "url(" + bgImage + ")";
  return (
    <section id="login" className="d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body text-center">
                <div className="login-logo">
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block"
                    alt="logo"
                  />
                </div>

                <h2 className="login-title">Welcome</h2>
                <p className="login-text">
                  To Wisdome, your membership app. <br />
                  Please log in to access your membership.{" "}
                </p>
                <h2 className="login-title">Access Code</h2>
                <p className="login-msg">
                  Enter Access code received in your invite email
                </p>
                <form>
                  <div className="form-group mb-0 justify-content-center d-flex">
                    <input
                      type="text"
                      maxLength="8"
                      className="form-control text-center w-50"
                      placeholder="Enter Access Code"
                      name="access_code"
                      value={accessCode}
                      onChange={(e) => {
                        setAccessCode(e.target.value);
                      }}
                    />
                  </div>
                </form>

                <h2 class="login-or">OR</h2>
                {/* <p class="login-msg2">Don't have an account? 
              <a href="#" class="join">Join us</a>
              </p> */}

                <form name="form" onSubmit={submitForm}>
                  <div className="form-group mb-3  text-left">
                    <input
                      maxLength={60}
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <span className="errormessage">{loginErrors.email}</span>
                  </div>
                  <div className="form-group mb-3 text-left">
                    <div className="input-group">
                      <input
                        type={passwordType}
                        className="form-control"
                        placeholder="Enter Password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <div
                        className="input-group-append"
                        onClick={showPassword}
                      >
                        <div className="input-group-text">
                          <span className="fas fa-eye" />
                        </div>
                      </div>
                    </div>
                    <span className="errormessage">{loginErrors.password}</span>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="remember"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="remember"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="text-right">
                        {/* <a href="#" className="forgot-password">Forgot Password?</a>   */}
                        <NavLink
                          className="forgot-password"
                          to="/forgot-password"
                        >
                          Forgot Password?
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button type="submit" className="btn btn-info">
                        Login
                      </button>
                    </div>
                    {loginError && loginError !== "" && (
                      <span className="errormessage" style={{ fontSize: 13 }}>
                        {loginError}
                      </span>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
