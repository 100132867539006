export default function WelcomeSection(props) {
  return (
    <section className="explore-box" style={props.dashboardColor}>
      <div className="row company-info" style={{marginBottom: '0rem' }}>
        <div className="col-xs-6 col-sm-6 col-md-2">
          <img
            src={props.subadminImage}
            className="company-img mr-2 img-fluid"
            alt=""
          />
        </div>
        <div className="col-xs-6 col-sm-6 col-md-10">
          <p className="company-name">
            {props.companyName} <br />
            <small>{props.membershipName}</small>
          </p>
        </div>
      </div>
      <label className="cinfo-title" style={{ color: "white", fontSize: '1.5rem', marginBottom: '0rem' }}>
        Explore content in your membership in multiple ways below - Select the
        area you wish to visit below
      </label>
      <br />
      <span>
        <i className="fas fa-arrow-down" style={{ fontSize: '1.5rem' , marginBottom: '1rem'  }}/>
      </span>
    </section>
  );
}