import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import close from "../../images/enduser/cross.svg";
import success from "../../images/audio/success.svg";
import $ from "jquery";

export default function SuccessPopup({message}) {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => setIsOpen(false);

  $(document).ready(function () {
    $(".sucessPopup").attr("id", "playlistAddedModal");
  });
  return (
    <>
      <Modal
        className="sucessPopup"
        show={isOpen}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header className="justify-content-end align-items-end border-0 pb-0">
          <button
            onClick={closeModal}
            type="button"
            className="close ml-5"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img src={close} />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={success} className="success-img img-fluid"></Image>
          <h4 className="title">{message}</h4>
        </Modal.Body>
      </Modal>
    </>
  );
}
