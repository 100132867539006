import React, { Component } from 'react'; 
import Header from '../components/common/Header'; 
import rightarrow from '../images/enduser/right-arrow.svg';
import { NavLink } from 'react-router-dom';
import { getUser, getAdminSubscriptions,updateUser } from '../helper/service'

export default class Profile extends Component {

    constructor(props){
      super(props);
      this.state = {
        updateUser:{},
        password:{},
        user:{},
        subscription:[],
        adminSubscriptions:[],
        filePreview:null,
        image: null
      }
    }


    handlePassword = (e) => {
      let password = this.state.password

      
    }

    handleImage = (e) => {
      e.preventDefault()
      let reader = new FileReader();
      let file = e.target.files[0]
   
   
      reader.onloadend = () => {
        let newData = {...this.state.updateUser}
          this.setState({
              image: file,
              filePreview: reader.result
          });

          newData.image = this.state.image
          this.setState({updateUser:newData})
      }
      
      
    reader.readAsDataURL(file)
   
    }

    handleBlur = (e) => {
      e.preventDefault()
     

      let newData = {...this.state.updateUser}
      newData[e.target.name] = e.target.value;
   
      this.setState({updateUser: newData})
     
      console.log(newData)
    }

    clearInputs = () => {
      document.getElementById('firstname22').value = ''
      document.getElementById('mobile22').value = ''
      document.getElementById('email22').value = ''
    }

   updateEndUser = async (e) =>{
    e.preventDefault()
    let id = localStorage.getItem('user_id')
    if(Object.keys(this.state.updateUser).length !== 0){

     let user =  await updateUser(this.state.updateUser,id)
     document.getElementById('firstname22').value = ''
     document.getElementById('mobile22').value = ''
     document.getElementById('email22').value = ''
     if(user) 
     {
       alert('successfully updated user');
       window.location.reload();
     }
    }else alert('form is empty')

   }
    async componentDidMount(){
      let id = localStorage.getItem('user_id')
      let subAdminId = localStorage.getItem('subadminId')
      let data = await getUser(id);
      this.setState({user: data.result.validUser})
     let subscription = await getAdminSubscriptions(subAdminId)


     if(subscription.result.subs.length !==0){
       this.setState({adminSubscriptions: subscription.result.subs})
     }

      if(data.result.validUser.subscriptionId.length !== 0){
        this.setState({subscription: data.result.validUser.subscriptionId})
      }
      

    }
    render() {
      
      // const   image = localStorage.getItem('image');
      // const mobile = localStorage.getItem('mobile');
      // const username = localStorage.getItem('firstName');
      // const email = localStorage.getItem('email');
      const { image, mobile, firstName, email} = this.state.user
        return (
            <div className="wrapper">
            <Header/>
           

            <div className="content-wrapper">    
              <section className="profile">      
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                      <div className="nav flex-column nav-pills" id="profile-tab" role="tablist" aria-orientation="vertical">
                        <a className="nav-link active" id="account-details-tab" data-toggle="pill" href="#account-details" role="tab" aria-controls="account-details" aria-selected="true"><img src={rightarrow} className="invisible mr-2" alt="navicon" /> Account Details</a>
                        <a className="nav-link" id="edit-account-tab" data-toggle="pill" href="#edit-account" role="tab" aria-controls="edit-account" aria-selected="false"><img src={rightarrow} className="invisible mr-2" /> Edit Account</a>
                        <a className="nav-link" id="change-password-tab" data-toggle="pill" href="#change-password" role="tab" aria-controls="change-password" aria-selected="false"><img src={rightarrow} className="invisible mr-2" /> Change Password</a>
                        <a className="nav-link" id="notifications-settings-tab" data-toggle="pill" href="#notifications-settings" role="tab" aria-controls="notifications-settings" aria-selected="false"><img src={rightarrow} className="invisible mr-2" /> Notifications Settings</a>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                      <div className="tab-content" id="profile-tabContent">
                        <div className="tab-pane fade show active" id="account-details" role="tabpanel" aria-labelledby="account-details-tab">
                          <h2 className="title">Account Details</h2>
                          <img src={image} className="user-img" />
                          <div className="row details">
                            <span className="col-sm-5">Username</span>
                            <div className="col-sm-7">
                              <label>{firstName}</label>
                            </div>
                          </div>
                          <div className="row details">
                            <span className="col-sm-5">Contact no.</span>
                            <div className="col-sm-7">
                              <label>{mobile}</label>
                            </div>
                          </div>
                          <div className="row details">
                            <span className="col-sm-5">Email Address</span>
                            <div className="col-sm-7">
                              <label>{email}</label>
                            </div>
                          </div>
                          <h2 className="title">Your Plans</h2>
                          {this.state.subscription.map((sub) => {
                            return (
                              <div className="d-flex flex-wrap">
                            <div className="card plans">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="plan-name">{sub.subsId.name}</p>
                                  </div>
                                  <div className="col-6">
                                    <p className="plan-fee text-right">Monthly / {sub.subsId.substype}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <p className="subsc-date">Subscription Date</p>
                                  </div>
                                  <div className="col-6">
                                    <p className="subsc-date text-right">{sub.createdAt.slice(0,10)} | {sub.createdAt.slice(11,16)}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <p className="expiry-date">Expiry Date</p>
                                  </div>
                                  <div className="col-6">
                                    <p className="expiry-date text-right">2021-11-12 | 10:40</p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <button className="btn btn-custom">Renew Plan</button>
                                </div>
                              </div>
                            </div>
                          </div>
                            )
                          })}
                          <h2 className="title">Explore Plans</h2>
                          <div className="d-flex flex-wrap">
                            {this.state.adminSubscriptions.map((sub) => {
                              return (
                                <div className="card explore-plans">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-6">
                                      <p className="plan-name">{sub.name}</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="plan-fee text-right">Monthly / {sub.substype}</p>
                                    </div>
                                  </div>
                                  <p>{sub.description}</p>
                                  <div className="d-flex justify-content-center">
                                  <NavLink to="/plan-details"><button className="btn btn-custom">Purchase Plan</button></NavLink>
                                  </div>
                                </div>
                              </div>
                              )
                            })}
                            {/* <div className="card explore-plans">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="plan-name">BRONZE PLAN</p>
                                  </div>
                                  <div className="col-6">
                                    <p className="plan-fee text-right">Monthly / $55</p>
                                  </div>
                                </div>
                                <p>It is a long established fact that a reader will be distracted the readable content of a page when looking at its layout</p>
                                <div className="d-flex justify-content-center">
                                <NavLink to="/plan-details"><button className="btn btn-custom">Purchase Plan</button></NavLink>
                                </div>
                              </div>
                            </div> */}
                            {/* <div className="card explore-plans">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-6">
                                    <p className="plan-name">GOLD PLAN</p>
                                  </div>
                                  <div className="col-6">
                                    <p className="plan-fee text-right">Monthly / $125</p>
                                  </div>
                                </div>
                                <p>It is a long established fact that a reader will be distracted the readable content of a page when looking at its layout</p>
                                <div className="d-flex justify-content-center">
                                  <NavLink to="/plan-details"><button className="btn btn-custom">Purchase Plan</button></NavLink>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="edit-account" role="tabpanel" aria-labelledby="edit-account-tab">
                          <h2 className="title">Edit Account</h2>
                          <div className="d-flex align-items-center flex-column mb-3">
                            {this.state.filePreview !== null ? <img src={this.state.filePreview} className="user-img" /> : <img src={image} className="user-img" /> }
                            {/* <img src={image} className="user-img" />                 */}
                            <label htmlFor="choose-file">
                              <i className="fas fa-pencil-alt"></i>
                            </label>
                            <input type="file" id="choose-file" style={{opacity:"0"}} onChange={(e) => this.handleImage(e)}/>
                          </div>
                          <form >
                            <div className="form-group">
                              <label>Username</label>
                              <input type="text" className="form-control" name="firstName" id="firstname22" placeholder={firstName} onChange={this.handleBlur}/>
                            </div>
                            <div className="form-group">
                              <label>Contact no.</label>
                              <input type="tel" className="form-control" name="mobile" id="mobile22" placeholder={mobile} onChange={this.handleBlur}/>
                            </div>
                            <div className="form-group">
                              <label>Email Address</label>
                              <input type="email" className="form-control" name="email" id="email22" placeholder={email} onChange={this.handleBlur}/>
                            </div>
                            <div className="d-flex justify-content-end flex-wrap flex-sm-nowrap">
                              <button type="button" className="btn btn-white" onClick={this.clearInputs}>Cancel</button>
                              <button type="submit" className="btn btn-blue" onClick={this.updateEndUser}>Save</button>
                            </div>
                          </form>
                        </div>
                        <div className="tab-pane fade" id="change-password" role="tabpanel" aria-labelledby="change-password-tab">
                          <h2 className="title">Change Password</h2>
                          <form>
                            <div className="form-group">
                              <label>Current Password</label>
                              <input type="password" className="form-control" placeholder="********w7et732w" onChange={this.handlePassword}/>
                            </div>
                            <div className="form-group">
                              <label>New Password</label>
                              <input type="password" className="form-control" placeholder="@3434********w7et732w" onChange={this.handlePassword}/>
                            </div>
                            <div className="form-group">
                              <label>Repeat new Password</label>
                              <input type="password" className="form-control" placeholder="@3434********w7et732w" onChange={this.handlePassword}/>
                            </div>
                            <div className="d-flex justify-content-end flex-wrap flex-sm-nowrap">
                              <button type="button" className="btn btn-white">Cancel</button>
                              <button type="submit" className="btn btn-blue">Set new password</button>
                            </div>
                          </form>
                        </div>
                        <div className="tab-pane fade" id="notifications-settings" role="tabpanel" aria-labelledby="notifications-settings-tab">
                          <h2 className="title">Notifications Settings</h2>
                          <h3 className="sub-title">App Updates</h3>
                          <div className="d-flex justify-content-between">
                            <p className="notification-text">Latest Update News <br />Getting new features and the latest updates on the Member App</p>
                            <div className="custom-control custom-switch">
                              <input type="checkbox" className="custom-control-input" id="appLatestUpdate" />
                              <label className="custom-control-label" htmlFor="appLatestUpdate" />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="notification-text">Membership News <br />News &amp; Events for you</p>
                            <div className="custom-control custom-switch">
                              <input type="checkbox" className="custom-control-input" id="appMembershipNews" />
                              <label className="custom-control-label" htmlFor="appMembershipNews" />
                            </div>
                          </div>
                          <h3 className="sub-title">Library Updates</h3>
                          <div className="d-flex justify-content-between">
                            <p className="notification-text">Membership Content <br />Newly added audio, content, music &amp; pictures items for you</p>
                            <div className="custom-control custom-switch">
                              <input type="checkbox" className="custom-control-input" id="libraryLatestUpdate" />
                              <label className="custom-control-label" htmlFor="libraryLatestUpdate" />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="notification-text">Recomended Content<br /> Newly added audio, content, music &amp; pictures items for you</p>
                            <div className="custom-control custom-switch">
                              <input type="checkbox" className="custom-control-input" id="libraryMembershipNews" />
                              <label className="custom-control-label" htmlFor="libraryMembershipNews" />
                            </div>
                          </div>
                          <div className="d-flex justify-content-end flex-wrap flex-sm-nowrap">
                            <button type="button" className="btn btn-white">Cancel</button>
                            <button type="submit" className="btn btn-blue">Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
    }
}
