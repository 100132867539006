/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import events from "../images/enduser/events.svg";
import socialwals from "../images/enduser/social wall.svg";
import library from "../images/enduser/library.svg";
import challenges from "../images/enduser/challenges.svg";
import courses from "../images/enduser/courses.svg";
import Header from "./common/Header";
import hexToRgba from "hex-to-rgba";
import { getAllUpcomingEvents, userInfo, getFolders } from "../helper/service";
import HomeCalendar from "./Dashboard/homeCalendar";
import WelcomeSection from "./Dashboard/welcomeSection";
import ConfirmationModal from "./Setting/ConfirmationModal";

export default function Dashboard() {
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    window.location.href = "/login";
  }

  //const [folders, setFolders] = useState([]);
  const [upcomingEventsMarkup, setUpcomingEventsMarkup] = useState([]);
  const [subsCheckModel, setSubsCheckModel] = useState(false);
  const [freeSubsCheckModel, setFreeSubsCheckModel] = useState(false);
  const [modelType, setModelType] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [modelMessage, setModelMessage] = useState("");
  const [paidSubsLink, setPaidSubsLink] = useState("");
  const [newsData, setNewsData] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showContent, setShowContent] = useState("");

  useEffect(() => {
    //let subadminId = localStorage.getItem("subadminId");
    // getFolders(subadminId).then((data) => {
    //   setFolders(data.result);

    // });
    getUserDataForSubsCheck();
    getUpcomingEvents();
  }, []);

  const getUserDataForSubsCheck = () => {
    const userId = localStorage.getItem("user_id");
    let subadminId = localStorage.getItem("subadminId");
    userInfo(userId).then((data) => {
      let subadminIndex = data?.result?.validUser?.subadminsId.findIndex(
        (part) => part?.subadminId?._id == subadminId
      );
      let subsDetails = data?.result?.validUser.subscriptionId[subadminIndex];

      let news =
        data?.result?.validUser?.subadminsId[subadminIndex]?.subadminId
          ?.newsData;
      const cleanedNews = news?.trim() === "<p><br></p>" ? "" : news;
      setNewsData(cleanedNews);
      setShowContent(cleanedNews);

      if (
        subsDetails?.subsId?.substype == "Free" &&
        subsDetails?.status == "Active" &&
        subsDetails?.showSubscriptionModel
      ) {
        setModelType("info");
        setModelTitle("Welcome");
        setModelMessage("You are added to a free Wisdome subscription");
        setFreeSubsCheckModel(false);
        setSubsCheckModel(true);
      } else if (
        subsDetails?.subsId?.substype == "Paid" &&
        subsDetails?.status != "Active" &&
        subsDetails?.showSubscriptionModel
      ) {
        setModelType("continue");
        setModelTitle("Subscription confirmation");
        setModelMessage(
          "Please confirm your subscription by settling the payment"
        );
        let link = `/checkout/${subsDetails?.subsId?._id}/${subsDetails?.subsPlan}`;
        setPaidSubsLink(link);
        setSubsCheckModel(true);
      }
    });
  };

  const getUpcomingEvents = () => {
    getAllUpcomingEvents().then((data) => {
      if (data.result.length > 0) {
        let upcomingEventsData = data.result.sort(function (a, b) {
          const firstEventDate = new Date(a.eventDate);
          const secondEventDate = new Date(b.eventDate);
          return firstEventDate - secondEventDate;
        });

        const eventsMarkup = upcomingEventsData.map((evnt) => {
          var originalUtcTimeStart = new Date(evnt.eventDate);
          var newUtcTimestart = originalUtcTimeStart.toISOString();
          var originalUtcTimeEnd = new Date(evnt.eventEndDate);
          var newUtcTimeEnd = originalUtcTimeEnd.toISOString();
          let eventDate = moment(newUtcTimestart).format("YYYY-MM-DD");
          let eventEndDate = moment(newUtcTimeEnd).format("YYYY-MM-DD");
          let startTime = moment(newUtcTimestart).format("HH:mm");
          let endTime = moment(newUtcTimeEnd).format("HH:mm");

          if (!evnt.hiddenForMembers) {
            return (
              <div
                className="event-list"
                style={{
                  borderLeft: `4px solid ${localStorage.getItem(
                    "subadminBrandColor"
                  )}`,
                }}
                key={evnt._id}
              >
                <div className="event-info">
                  <h5 className="event-title">{evnt.name} </h5>
                  <div className="d-flex">
                    <span className="event-time">
                      {eventDate} {startTime} | {eventEndDate} {endTime}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        setUpcomingEventsMarkup(eventsMarkup);
      }
    });
  };

  const toggleContent = () => {
    if (isExpanded) {
      setShowContent(
        newsData.length > 200 ? newsData.slice(0, 200) + "..." : newsData
      );
    } else {
      setShowContent(newsData);
    }
    setIsExpanded(!isExpanded);
  };

  const handleCloseModal = () => {
    setSubsCheckModel(false);
  };

  const BrandColor = localStorage.getItem("subadminBrandColor");
  const bgcolor = { backgroundColor: BrandColor };
  const bgcolor1 = { backgroundColor: BrandColor };
  const fontcolor = { color: BrandColor };
  const bColor = BrandColor + "b3";
  const bordercolor = { borderColor: bColor };
  const firstName = localStorage.getItem("firstName");
  const subadminBusiness = localStorage.getItem("subadminBusiness");
  const membershipName = localStorage.getItem("membershipName");
  const subadminLogo = localStorage.getItem("subadminLogo");
  const subadminImage = localStorage.getItem("subadminImage");
  let companyName = localStorage.getItem("companyName");
  var OpacityColor = BrandColor ? hexToRgba(BrandColor, "0.7") : "#000";
  const dashboardColor = { backgroundColor: OpacityColor };
  const borderColor = { borderColor: BrandColor, boxShadow: BrandColor };
  //console.log("colorcheck", BrandColor);
  const boxStyle = {
    boxShadow: "1px 3px 22px #E2E9F2",
    border: "1px solid #E8E8E8",
    borderRadius: "6px",
    padding: "5px",
    borderRadius: "5px",
    marginLeft: "3px",
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            {subsCheckModel && (
              <ConfirmationModal
                type={modelType}
                title={modelTitle}
                freeSubsCheckModel={freeSubsCheckModel}
                message={modelMessage}
                onClose={handleCloseModal}
                paidSubsLink={paidSubsLink}
              />
            )}

            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <h2>HOME</h2>
                <small>Welcome, {firstName}! </small>
              </div>
            </div>
          </div>
        </div>

        <WelcomeSection
          dashboardColor={dashboardColor}
          companyName={companyName}
          membershipName={membershipName}
          subadminImage={subadminImage}
        />

        {/* <section className="show-content">
                <div className="container">
                  <div className="template">
                    <div className="row">
                      <div className="col-3">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-6">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                      <div className="col-3">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                  <div className="template2">
                    <div className="row">
                      <div className="col-8">
                        <img src={book} width={650} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                    </div>
                  </div>
                  <div className="template3">
                    <div className="row">
                    <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                      <div className="col-4">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>                      
                    </div>
                  </div>
                </div>

              </section> */}

        <section className="row ml-2 pr-4">
          <div className="info-card col-md-12 col-lg-7 mt-sm-2">
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <div className="card">
                <div className="card-body">
                  <NavLink to="/content-library">
                    <div
                      className="img-border mx-auto d-block"
                      style={bordercolor}
                    >
                      <div className="img-bg" style={bgcolor}>
                        <img src={library} className="card-img-top" alt="" />
                      </div>
                    </div>
                    <h5 className="info-title">Library</h5>
                  </NavLink>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <NavLink to="/courses">
                    <div
                      className="img-border mx-auto d-block"
                      style={bordercolor}
                    >
                      <div className="img-bg" style={bgcolor}>
                        <img src={courses} className="card-img-top" alt="" />
                      </div>
                    </div>
                    <h5 className="info-title">Courses</h5>
                  </NavLink>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <NavLink to="/challenges-library">
                    <div
                      className="img-border mx-auto d-block"
                      style={bordercolor}
                    >
                      <div className="img-bg" style={bgcolor}>
                        <img src={challenges} className="card-img-top" alt="" />
                      </div>
                    </div>
                    <h5 className="info-title">Challenges</h5>
                  </NavLink>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <NavLink to="/events">
                    <div
                      className="img-border mx-auto d-block"
                      style={bordercolor}
                    >
                      <div className="img-bg" style={bgcolor}>
                        <img src={events} className="card-img-top" alt="" />
                      </div>
                    </div>
                    <h5 className="info-title">Events</h5>
                  </NavLink>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <NavLink to="/social-wall">
                    <div
                      className="img-border mx-auto d-block"
                      style={bordercolor}
                    >
                      <div className="img-bg" style={bgcolor}>
                        <img src={socialwals} className="card-img-top" alt="" />
                      </div>
                    </div>
                    <h5 className="info-title">Social Wall</h5>
                  </NavLink>
                </div>
              </div>
              {/* <div className="card">
                <div className="card-body" style={{ boxShadow: `5px 5px 20px ${BrandColor}` }}>
                <NavLink to="/audio-library">
                  <div className="img-border mx-auto d-block" style={bordercolor}>
                    <div className="img-bg" style={bgcolor}>
                      <img src={multimedia} className="card-img-top" alt="" />
                    </div>
                  </div>
                  <h5 className="info-title">Multimedia</h5>
                  </NavLink>
                </div>
              </div> */}
            </div>
            {newsData?.length > 0 ? (
              <div className="content-header dashboard-news">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="row ml-2 pr-4">
                      <div className="news-box" style={boxStyle}>
                        <h2 className="text-center">News</h2>
                        <br />
                        <p
                          className="description-content p-4"
                          dangerouslySetInnerHTML={{ __html: showContent }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="card calendar col-md-12 col-lg-5 mt-sm-2">
            <div className="card-body">
              <div className="d-flex justify-content-left text-center flex-wrap">
                <HomeCalendar />
              </div>
              <div className="admin-event mx-0">
                <div className="upcoming-event shadow-none">
                  {upcomingEventsMarkup}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="latest">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading">Latest on {subadminBusiness}</h2>
                  <h3 className="sub-heading">{membershipName}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-12">
                  <ul className="nav nav-tabs" id="latestTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="youFav-tab" data-toggle="tab" href="#youFav" role="tab" aria-controls="youFav" aria-selected="true">Your Favourites</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="suggEvent-tab" data-toggle="tab" href="#suggEvent" role="tab" aria-controls="suggEvent" aria-selected="false">Suggested Events</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="latestCont-tab" data-toggle="tab" href="#latestCont" role="tab" aria-controls="latestCont" aria-selected="false">Latest Content</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab" style={fontcolor}>View all <i className="fas fa-angle-right" /></a>
                    </li>
                  </ul>
                  <div className="tab-content" id="latestTabContent">
                    <div className="tab-pane fade show active" id="youFav" role="tabpanel" aria-labelledby="youFav-tab">
                      <div className="d-flex align-items-center justify-content-left flex-wrap">
                        <div className="card">
                          <img src={image1} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={calendar} />
                          </div>
                          <span className="badge badge-over">1 ticket left</span>
                          <h5 className="title">Why choose a theme that ...</h5>
                          <p className="text">EVENT</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={video} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">VIDEO</p>
                          <p className="text">Sep 17, 2020 - 10:35 min</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">PAGE</p>
                          <p className="text">Sep 17, 2020 - 3 min read</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={Group69025} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">CHALLENGE</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">CONTENT</p>
                          <p className="text">Sep 17, 2020 - 5 pages</p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="suggEvent" role="tabpanel" aria-labelledby="suggEvent-tab">
                      <div className="d-flex align-items-center justify-content-left flex-wrap">
                        <div className="card">
                          <img src={image1} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={calendar1} />
                          </div>
                          <span className="badge badge-over">1 ticket left</span>
                          <h5 className="title">Why choose a theme that ...</h5>
                          <p className="text">EVENT</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={video} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">VIDEO</p>
                          <p className="text">Sep 17, 2020 - 10:35 min</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">PAGE</p>
                          <p className="text">Sep 17, 2020 - 3 min read</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={Group69025} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">CHALLENGE</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">CONTENT</p>
                          <p className="text">Sep 17, 2020 - 5 pages</p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="latestCont" role="tabpanel" aria-labelledby="latestCont-tab">
                      <div className="d-flex align-items-center justify-content-left flex-wrap">
                        <div className="card">
                          <img src={image1} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={calendar1} />
                          </div>
                          <span className="badge badge-over">1 ticket left</span>
                          <h5 className="title">Why choose a theme that ...</h5>
                          <p className="text">EVENT</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={video} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">VIDEO</p>
                          <p className="text">Sep 17, 2020 - 10:35 min</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">PAGE</p>
                          <p className="text">Sep 17, 2020 - 3 min read</p>
                        </div>
                        <div className="card">
                          <img src={image2} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={Group69025} />
                          </div>
                          <h5 className="title">How to choose color</h5>
                          <p className="text">CHALLENGE</p>
                          <p className="text">Sep 17, 2020 - 9:20AM</p>
                        </div>
                        <div className="card">
                          <img src={image3} className="card-img-top" alt="" />
                          <div className="img-over">
                            <img src={files} />
                          </div>
                          <h5 className="title">Choose a theme</h5>
                          <p className="text">CONTENT</p>
                          <p className="text">Sep 17, 2020 - 5 pages</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="card calendar">
                    <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                      <h3 className="card-title pt-3">Calendar</h3>
                      <select className="form-control">
                        <option>2 Sep, 22 - 7 Sep, 22</option>
                        <option>7 Sep, 22 - 12 Sep, 22</option>
                        <option>12 Sep, 22 - 18 Sep, 22</option>
                        <option>15 Sep, 22 - 20 Sep, 22</option>
                        <option>20 Sep, 22 - 25 Sep, 22</option>
                      </select>
                      <img src={Group67949} width={52} height={52} />
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-left text-center flex-wrap">
                        <div className="mb-2">
                          <div className="day active" style={bgcolor1}>
                            <span>2</span><br />
                            <span>Mon</span>
                          </div>
                          <span className="dots active" style={bgcolor1} />
                        </div>
                        <div className="mb-2">
                          <div className="day">
                            <span>3</span><br />
                            <span>Tue</span>
                          </div>
                          <span className="dots" />
                        </div>
                        <div className="mb-2">
                          <div className="day">
                            <span>4</span><br />
                            <span>Wed</span>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="day">
                            <span>5</span><br />
                            <span>Thu</span>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="day">
                            <span>6</span><br />
                            <span>Fri</span>
                          </div>
                          <span className="dots" />
                        </div>
                        <div className="mb-2">
                          <div className="day">
                            <span>7</span><br />
                            <span>Sat</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

        {/* <section className="suggested">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="sub-heading">Suggested</h3>
                  <ul className="nav nav-tabs" id="suggestedTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">All</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="post-tab" data-toggle="tab" href="#post" role="tab" aria-controls="post" aria-selected="false">Posts</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="videos-tab" data-toggle="tab" href="#videos" role="tab" aria-controls="videos" aria-selected="false">Videos</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="photos-tab" data-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="true">Photos</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="courses-tab" data-toggle="tab" href="#courses" role="tab" aria-controls="courses" aria-selected="false">Courses</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" id="challenges-tab" data-toggle="tab" href="#challenges" role="tab" aria-controls="challenges" aria-selected="false">Challenges</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="suggestedTabContent">
                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                      <div className="d-flex align-items-center justify-content-left flex-wrap">
                        {folders.length !== 0 ?
                          folders.slice(0, 8).map((folder) => {
                            return (<div className="card">
                              <img src={folder.image} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69361} /> 
                              </div>
                              <h5 className="title">{folder.title}</h5>
                              <p className="text">{folder.subtitle}</p>
                            </div>)
                          }) : ""
                        }

                        
                        {/* <div className="card">                                                
                              <img src={all1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={calendar1} />
                              </div>                       
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                                                        
                            <div className="card">
                              <img src={all2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <img src={all3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div>
                            <div className="card">
                              <img src={all1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>
                               <div className="card">
                              <img src={all2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69362} />
                              </div>
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <img src={all3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69361} />
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div> 
                      </div>
                    </div>
                    <div className="tab-pane fade" id="post" role="tabpanel" aria-labelledby="post-tab">
                      <div className="row">
                        <div className="col-xl-8">
                          <h5 className="title">POST NOW</h5>
                          <div className="card post-now">
                            <div className="card-body">
                              <div className="d-flex align-items-start">
                                <img src={adminimg} className="mr-2" alt="User Image" />
                                <textarea className="form-control" rows={6} placeholder="What are you thinking?" defaultValue={""} />
                                <img src={Group68618} className="ml-2" alt="option" />
                              </div>
                              <div className="d-flex align-items-center">
                                <img src={ButtonIcon} className="mr-2" alt="post" />
                                <img src={chat10} className="mr-2" alt="video" />
                                <img src={chat11} className="mr-2" alt="add" />
                                <select className="form-control ml-auto">
                                  <option>Group A you're in</option>
                                  <option>Group B you're in</option>
                                  <option>Group C you're in</option>
                                  <option>Group D you're in</option>
                                  <option>Group E you're in</option>
                                </select>
                                <img src={Group68616} alt="submit" width={65} height={38} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <h5 className="title">LATEST POSTS <span><a href='#'>View all </a> <i className="fas fa-angle-right" /> </span></h5>
                          <div className="card latest-post">
                            <div className="card-body">
                              <div className="media mb-1">
                                <img src={adminimg} className="mr-3" alt="user" width={38} height={38} />
                                <div className="media-body">
                                  <h5 className="mt-0 title">Dustin Houston <span className="course-no">Course 101</span></h5>
                                  <p className="text">5min ago</p>
                                </div>
                              </div>
                              <p className="content">Whether its a driving tour, a cruise or a bus, leaf viewing is a great way to spend a fall vacation</p>
                              <div className="card-columns mb-2">
                                <img src={photo1} />
                                <img src={photo2} />
                                <img src={photo3} />
                                <img src={photo4} />
                                <img src={photo2} />
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="like mr-3">
                                  <img src={likeo} alt="post" style={{ opacity: "0.4" }} /> 326
                                </div>
                                <div className="comment mr-3">
                                  <img src={chat9} alt="video" /> 148
                                </div>
                                <div className="photo mr-3">
                                  <img src={ButtonIcon} alt="add" /> 15
                                </div>
                                <img src={heart4} className="ml-auto" alt="like" />
                              </div>
                              <div className="d-flex align-items-center">
                                <input type="text" name className="form-control" placeholder="Leave a comment" />
                                <img src={Group68616} alt="submit" width={65} height={38} />
                              </div>
                            </div>
                          </div>
                          <div className="card latest-post">
                            <div className="card-body">
                              <div className="media mb-1">
                                <img src={adminimg} className="mr-3" alt="user" width={38} height={38} />
                                <div className="media-body">
                                  <h5 className="mt-0 title">Sam Brighton <span className="course-no">Interface Challenge</span></h5>
                                  <p className="text">7min ago</p>
                                </div>
                              </div>
                              <p className="content">Weekend is here! Enjoy your research and challenge for next week.</p>
                              <div className="d-flex align-items-center mb-3">
                                <div className="like mr-3 d-none">
                                  <img src={chat9} alt="post" /> 326
                                </div>
                                <div className="comment mr-3 d-none">
                                  <img src={chat9} alt="video" /> 148
                                </div>
                                <div className="photo mr-3 d-none">
                                  <img src={chat9} alt="add" /> 15
                                </div>
                                <img src={heart4} className="ml-auto" alt="like" />
                              </div>
                              <div className="d-flex align-items-center">
                                <input type="text" name className="form-control" placeholder="Leave a comment" />
                                <img src={Group68616} alt="submit" width={65} height={38} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    <div className="tab-pane fade" id="videos" role="tabpanel" aria-labelledby="videos-tab">
                      <div className="d-flex flex-wrap">
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="video-img" alt="video" />
                            <img src={play} className="play-btn" alt="play-btn" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo6} className="video-img" alt="video" />
                            <img src={play} className="play-btn" alt="play-btn" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likesmall} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">The Journey of Dreaming</h5>
                          <p className="text">Sita Rosida</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo7} className="video-img" alt="video" />
                            <img src={play} className="play-btn" alt="play-btn" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Make: Getting Started With Pro...</h5>
                          <p className="text">Casey Reas &amp; Ben Fry</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="video-img" alt="video" />
                            <img src={play} className="play-btn" alt="play-btn" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="photos-tab">
                      <div className="d-flex flex-wrap">
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo6} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likesmall} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">The Journey of Dreaming</h5>
                          <p className="text">Sita Rosida</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo7} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Make: Getting Started With Pro...</h5>
                          <p className="text">Casey Reas &amp; Ben Fry</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo6} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likesmall} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">The Journey of Dreaming</h5>
                          <p className="text">Sita Rosida</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo7} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Make: Getting Started With Pro...</h5>
                          <p className="text">Casey Reas &amp; Ben Fry</p>
                        </div>
                        <div className="card">
                          <div className="d-flex align-items-center justify-content-left">
                            <img src={photo5} className="photo" alt="video" />
                            <div className="d-flex flex-column side-icon ml-3">
                              <img src={likeo} alt="like" />
                              <img src={download} alt="download" />
                            </div>
                          </div>
                          <h5 className="title">Feel Free To Focus</h5>
                          <p className="text">Michael Hyaat</p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                      <div className="d-flex flex-wrap">
                        <div className="card">
                          <img src={course1} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 36 Lessons
                            </div>
                          </div>
                          <p className="timing">Sep 17, 2020 - 9:20AM</p>
                          <h5 className="title">Everything You Need to Know About Business</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course3} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 36 Lessons
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">Statistics Data Science and Business Analysis</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">FREE</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course4} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 36 Lessons
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course3} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 36 Lessons
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="challenges" role="tabpanel" aria-labelledby="challenges-tab">
                      <div className="d-flex flex-wrap">
                        <div className="card">
                          <img src={course4} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 6 Days
                            </div>
                          </div>
                          <p className="timing">Sep 17, 2020 - 9:20AM</p>
                          <h5 className="title">Everything You Need to Know About Business</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course1} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 6 Days
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">Statistics Data Science and Business Analysis</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">FREE</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course2} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 6 Days
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                        <div className="card">
                          <img src={course3} className="course-img" alt="course" />
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="user">
                              <img src={likeo} alt="like" /> 25
                            </div>
                            <div className="like">
                              <img src={likesmall} alt="download" /> 28,500
                            </div>
                            <div className="lessons">
                              <img src={download} alt="download" /> 6 Days
                            </div>
                          </div>
                          <p className="timing">OPEN</p>
                          <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                          <div className="d-flex justify-content-between align-items-center align-content-center">
                            <div className="trainee">
                              <img src={like} className="mr-2" alt="like" />
                              <span>Nicole Brown</span>
                            </div>
                            <p className="fees">$49.65</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
      </div>
    </div>
  );
}
