import React, { Component } from 'react';
import logo from '../images/logo.png';
import membership from '../images/membership-bg.svg';
import { NavLink } from 'react-router-dom';
import {memberlogin} from '../helper/service';
 


const initialState = {
  fields: {},
  errors: {},
  isSignup:false,
  error:false
}

// import { login } from "../helper/service";

export default class MembershipLogin extends Component {

  constructor(props){
    super(props);
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
   }
  

   onChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value; 
    this.setState({fields});
  }


  submitForm(e){
    e.preventDefault();    
    const {email,password,} = this.state.fields;
  
    if(this.validate()){
      let data = {
        "email": email,
        "password": password
      }
      
     memberlogin(data)
    }  
  }


  validate = () =>{

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;  
    if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "*Username is required";
      } else {
        errors["email"] = "";
      }
      
      if (!fields["password"]) {
        formIsValid = false;
        errors["password"] = "*Password is required";
      } else {
        errors["password"] = "";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
  }


  render() {  

    document.body.style.backgroundImage = "url("+membership+")";

    return (
        <section id="user-membership-login" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-body text-center">

                <div className="login-logo">
                  <img src={logo} className="img-fluid mx-auto d-block" alt="logo" />
                </div>
                
                  <div className="company-info d-flex justify-content-center align-items-center membership-box-color">
                    <img src="images/company-logo.svg" className="mr-2 img-fluid" alt="" />
                    <p className="company-name">Design Company <br /><small>YOUR MEMBERSHIP</small></p>
                  </div>
                  <h2 className="user-membership-login-title">Password</h2>
                  <p className="user-membership-login-text">Please input your password here for the <br />membership above.</p>          
                  <p className="user-membership-login-text">If this is your first time, you may have received a new<br /> password to log into this memberships app.</p>
                  <p className="user-membership-login-text">Once in, you can change your password in your profile.</p>
                <form name="form" onSubmit={this.submitForm}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fas fa-envelope" />
                        </div>
                      </div>
                      <input type="text" className="form-control" placeholder="Username" name="email" onChange={this.onChange}/>
                      <span className="errormessage">{this.state.errors.email}</span>
                    </div>
                    <div className="input-group input2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fas fa-lock" />
                        </div>
                      </div>
                      <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.onChange} />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-eye" />
                        </div>
                      </div>
                      <span className="errormessage">{this.state.errors.password}</span>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="remember" required />
                          <label className="custom-control-label" htmlFor="remember">Remember Me</label>
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        <NavLink to="forgot-password">Forgot Password?</NavLink>
                      </div>
                      <div className="col-12 text-center"> 
                        <button type="submit" className="btn btn-info">Login</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
