import React, { useEffect, useState } from 'react'

const Text = ({ containers, block }) => {
  const [contentText, setContentText] = useState();
  useEffect(() => {
    setContentText(containers[0]?.text.bodyText)
  })
  return (
    <div>
      <div>
        <p dangerouslySetInnerHTML={{ __html: contentText }}></p>
      </div>
    </div>
  )
}

export default Text
