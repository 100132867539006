import React, { Component } from 'react'
import Header from '../common/Header';

import MediaTextMedia from './preview/MediaTextMedia';
import TextMediaText from './preview/TextMediaText';
import MediaText from './preview/MediaText';
import Media from './preview/Media';
import TextMedia from './preview/TextMedia';
import { NavLink } from 'react-router-dom';
import Text from './preview/Text';
import TextTextText from './preview/TextTextText';
import TextText from './preview/TextText';
import MediaMediaMedia from './preview/MediaMediaMedia';
import { getContent } from "../../helper/service";
import goback from "../../images/dashboard/go-back.svg";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { startOrCompleteContent, getNextContent } from '../../helper/service.js'
export default class Contentview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentData: [],
      propertise: [],
      template1: [],
      template2: [],
      containerData: '',
      contentFolderName: "",
      contentId: '',
      pageTitle: "",
      brandColor: '',
      showNextContent: false,
      nextContentId: '',
      contentPageStatus:''
    }
  }

  async componentDidMount() {
    //let id = localStorage.getItem('content_id'); 
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');
    this.setState({ brandColor: localStorage.getItem('subadminBrandColor') })
    const data = await getContent(id);
    if (data.result.propertise.length > 0) {
      this.setState({ pageTitle: data.result.propertise[0].title });
    }
    let filterContentData;
    if (data.result) {
      filterContentData = data.result.contentData.filter(item => item?.container)
      this.setState({ contentData: filterContentData, contentId: data.result._id })
      this.setState({ propertise: data.result.propertise,contentPageStatus:data.result.propertise[0].contentStatus })
    }
    this.setState({ contentFolderName: data.result.folderId.title })
    if (filterContentData.length !== 0) {
      if (filterContentData[0].container) {
        if (filterContentData[0].container[0].length !== 0) {

          var i = -1;
          const sorted = filterContentData.sort(function (a, b) {
            return a.sortingOrder - b.sortingOrder;
          });
          const containerData = sorted.map(function (subs, index) {
            i++;

            if (i >= 0) {

              if (subs.container) {
                if (subs.temp_id.temp_attributes[0] == "Media" && subs.temp_id.temp_attributes[1] == "Text" && subs.temp_id.temp_attributes[2] == "Media") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <MediaTextMedia containers={subs.container} />
                    </>
                  )
                }
              }

              if (subs.container) {
                if (subs.temp_id.temp_attributes[0] == "Text" && subs.temp_id.temp_attributes[1] == "Media" && subs.temp_id.temp_attributes[2] == "Text") {
                  return (
                    <>
                      <TextMediaText containers={subs.container} />
                    </>
                  )
                }
              }

              if (subs.container) {
                if (subs.temp_id.temp_attributes[0] == "Text" && subs.temp_id.temp_attributes[1] == "Media") {
                  return (
                    <>
                      <TextMedia containers={subs.container} />
                    </>
                  )
                }
              }

              if (subs.container) {

                if (subs.temp_id.temp_attributes[0] == "Media" && subs.temp_id.temp_attributes[1] == "Text") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <MediaText containers={subs.container} />
                    </>
                  )
                }
              }
              if (subs.container) {
                if (subs.temp_id.temp_attributes[0] == "Media" && subs.temp_id.temp_attributes[1] == "Media" && subs.temp_id.temp_attributes[2] == "Media") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <MediaMediaMedia key={index} containers={subs.container} />
                    </>
                  )
                }
              }
              if (subs.container) {
                if (
                  subs.temp_id.temp_attributes[0] == "Media" && subs.container.length == 1

                ) {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <Media
                        key={Math.random()}
                        containers={subs.container}
                      />
                    </>
                  );
                }
              }
              if (subs.container) {

                if (subs.temp_id.temp_attributes[0] == "Heading") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <Text containers={subs.container} block={i} />
                    </>
                  )
                }
              }
              if (subs.container) {

                if (subs.temp_id.temp_attributes[0] == "Text" && subs.temp_id.temp_attributes[1] == "Text" && subs.temp_id.temp_attributes[2] == "Text") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <TextTextText containers={subs.container} block={i} />
                    </>
                  )
                }
              }
              if (subs.container) {

                if (subs.temp_id.temp_attributes[0] == "Text" && subs.temp_id.temp_attributes[1] == "Text") {
                  console.log(subs.temp_id.temp_attributes);
                  return (
                    <>
                      <TextText containers={subs} block={i} />
                    </>
                  )
                }
              }

            }
          })

          this.setState({
            containerData: containerData
          })
        }
      }

    }

  }
  async startAndCommpleteContent() {
    let data = this.state.propertise[0];
    if (data.previousContent == "" || data.previousContent == null || data.previousContent == undefined) {
      data.contentStatus = 'started';
      let res = await startOrCompleteContent(this.state.contentId, data);
      
      if (res.data.status == 200) {
        this.setState({contentPageStatus:'started'})
        alert("Content started successfully.")
      }
    }
    else if (data.previousContent != "" && !data.contentCompleteRestriction) {
      data.contentStatus = 'started';
      let res = await startOrCompleteContent(this.state.contentId, data);
      if (res.data.status == 200) {
        this.setState({contentPageStatus:'started'})
        alert("Content started successfully.")
      }
    }

    else if (data.previousContent != "" && data.contentCompleteRestriction) {
      data.contentStatus = 'started';
      let res = await startOrCompleteContent(this.state.contentId, data);
      if (res.data.message == "Please complete first previous content.") {
        alert(res.data.message)
      }
      else {
        this.setState({contentPageStatus:'started'})
        alert("Content started successfully")
      }

    }

  }
  async completeContent() {
    let contentStatus = this.state.propertise[0].contentStatus;
    let data = this.state.propertise[0];
    if (contentStatus == "started") {
      data.contentStatus = "completed"
      let res = await startOrCompleteContent(this.state.contentId, data);
      console.log(res, "start and complete")
      if (res.status == 200) {
        this.setState({contentPageStatus:'completed'})
        let nextContent = await getNextContent(res.data.result._id)
        if (nextContent.data.result != null) {
          this.setState({ showNextContent: true, nextContentId: nextContent?.data?.result?._id })
        }
        else {
          alert("Content completed successfully")
        }
      }

    }

  }
  hideContentModal() {
    this.setState({ showNextContent: false })
  }
  goToNextContent() {
    this.setState({ showNextContent: false })
    window.location.href = `/view-content?id=${this.state.nextContentId}`
  }

  render() {
    if (this.state.contentData[0]) {
      //console.log(this.state.template1,this.state.template2)
    }

    let folderTitle = localStorage.getItem('selectedFolderTitle');

    return (
      <div className="wrapper">
        <Header />
        {this.state.showNextContent &&
          <Modal show={this.state.showNextContent} onHide={this.hideContentModal.bind(this)}>
            <Modal.Title style={{ padding: '.7em' }}>Lesson Completed</Modal.Title>
            <p style={{ padding: '.7em' }}>Content completed successfully. If you want to continue to next content then click on continue button  </p>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "30%", marginRight: "30%", justifyContent: "space-between", marginBottom: "1em" }}>
              <Button variant="secondary" style={{ width: "45%", textAlign: "center" }} onClick={this.hideContentModal.bind(this)}>
                Cancel
              </Button>

              <Button variant="primary" style={{ width: "45%", textAlign: "center" }} onClick={this.goToNextContent.bind(this)}>
                Continue
              </Button>

            </div>
          </Modal>
        }
        <div className="content-wrapper content-final-preview">
          <section className>
            <div className="container-fluid">
              <Link to="/content-library"><img src={goback} className="img-fluid mr-3 mt-5" /></Link>
              <div className="row heading">
                <h6 className="content-folder-name">Folder Name: {this.state.contentFolderName}</h6>


                <div className="col-lg-7">
                  {/* <h2 className="title"> <NavLink style={{color:'#2F4A92'}} to="/content-library" >Return to library</NavLink> View Your Content Page</h2>  */}
                  {/* <h2 className="title">View Your Content Page </h2>             */}
                </div>
                <div className="col-lg-5">
                  {/* <img src={preview} className="float-right" /> */}
                  {/* <a href="#" className="float-right"> */}
                  {/* <NavLink to="/content-page-setting" className="float-right">
                  <img src={setting} className="img-fluid" /> Page Settings
                  </NavLink> */}
                  {/* </a> */}
                </div>
              </div>
            </div>
            {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="#">{folderTitle} </a></li>
              <li className="breadcrumb-item active" aria-current="page">Create Page</li>
            </ol>
          </nav> */}
          </section>

          <div className="d-flex flex-row ml-5 mt-2">
            {
              (this.state.contentPageStatus == "" || this.state.contentPageStatus == " " || this.state.contentPageStatus == undefined || this.state.contentPageStatus == null) &&
              <>
                <i className="fa-solid fa-circle-play" style={{ color: `${this.state.brandColor}`, fontSize: '1.5em' }} onClick={this.startAndCommpleteContent.bind(this)}>
                </i>
                <span className="ml-2">Start</span>
              </>
            }
            {
              this.state.contentPageStatus == "completed" &&
              <>
                <i className="fa-solid fa-circle-play" style={{ color: `${this.state.brandColor}`, fontSize: '1.5em' }} onClick={this.startAndCommpleteContent.bind(this)}>
                </i>
                <span className="ml-2">Completed</span>
              </>
            }
            {
              this.state.contentPageStatus == "started" &&

              <>
                <i className="fa-solid fa-circle-stop ml-3" style={{ color: `${this.state.brandColor}`, fontSize: '1.5em' }} onClick={this.completeContent.bind(this)}></i>
                <span className="ml-2">Started</span>
              </>
            }
          </div>
          <div className='ml-5 mt-5'>
            <h3 className=''>{this.state.pageTitle}</h3>
          </div>


          <section>
            <div className="container-fluid">
              <div className="row no-gutters">
                {/* <div className="col-lg-1">
                  <NavLink to="/choose-template">
                <img src={addContent} className="img-fluid mt-3" />
                </NavLink>
              </div> */}
                <div className="col-lg-12">
                  {/* <h4 className="content-title">Create Content</h4> */}
                  <div className="create-content-scroll">
                    <div className="create-content">
                      {this.state.containerData}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}
