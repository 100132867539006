import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import countriesList from "../../components/country.json";
import Loader from "../Loader";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { validateAndApplyCoupon, validateCoupon } from "../../services/paymentService";

import {
  userInfo,
  getMembershipLevel,
  getSelectedEvent,
  subscribefree,
  startSubscription,
  getSaleSelectedCourse,
  startPurchaseCourse,
  startPurchaseEvent,
  enrollCourse,
  joinEvent,
} from "../../helper/service";

import secureCheckout from "../../images/checkout/secure-checkout.png";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";

const TaxRate = 0.1;

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigete = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});
  console.log(couponData, "couponData gggg")
  const [couponError, setCouponError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");
  console.log(props, "props data");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    country: "Australia",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [brandColor, setBrandColor] = useState("");
  const [plan, setPlan] = useState({});
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0.0);
  const [originalOrderPrice, setOriginalOrderPrice] = useState(0.0);
  const [orderTax, setOrderTax] = useState(0.0);
  const [discount, setDiscount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0.0);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    var isMonthlyPayment = props.subscriptionFrequency === "monthly";
    setIsMonthly(isMonthlyPayment);
    setBrandColor(localStorage.getItem("subadminBrandColor"));

    const userId = localStorage.getItem("user_id");
    userInfo(userId).then((data) => {
      let userData = {
        fullName:
          data.result.validUser.firstName +
          " " +
          data.result.validUser.lastName,
        email: data.result.validUser.email,
        phone: data.result.validUser.mobile,
      };
      setFormData(userData);

      let subsId = props.subscriptionId;
      if (subsId) {
        getMembershipLevel(subsId).then((subs) => {
          if (subs?.result?.validSubs) {
            var subscription = subs?.result?.validSubs;
            if (subscription) {
              setPlan(subscription);
              var orderMonthlyPrice =
                Math.round(subscription.amountMonthly * 100) / 100;
              var orderYearlyPrice =
                Math.round(subscription.amountYearly * 100) / 100;

              var originalPrice = isMonthlyPayment
                ? orderMonthlyPrice
                : orderYearlyPrice;
              var currentPrice = originalPrice / 1.1;
              var tax = currentPrice * TaxRate;

              setOrderPrice(currentPrice.toFixed(2));
              setOriginalOrderPrice(originalPrice);
              setOrderTax(tax.toFixed(2));
              setOrderTotal(originalPrice.toFixed(2));
            }
          }
        });
      } else if (props.courseId) {
        getSaleSelectedCourse(props.courseId).then((course) => {
          let selectedCourse = course.result[0];
          let singleCourse = {
            tier: selectedCourse.title,
            description: selectedCourse.shortDescription,
          };
          var orderPrice = Math.round(selectedCourse.amount * 100) / 100;
          var currentPrice = orderPrice / 1.1;
          var tax = currentPrice * TaxRate;
          setPlan(singleCourse);
          setOrderPrice(currentPrice.toFixed(2));
          setOriginalOrderPrice(orderPrice);
          setOrderTax(tax.toFixed(2));
          setOrderTotal(orderPrice.toFixed(2));
        });
      } else if (props.eventId) {
        getSelectedEvent(props.eventId).then((event) => {
          let selectedEvent = event.result;
          let singleEvent = {
            tier: selectedEvent.name,
            description: selectedEvent.description.replace(/<.*?>/g, ""),
          };
          var orderPrice = Math.round(selectedEvent.eventAmount * 100) / 100;
          var currentPrice = orderPrice / 1.1;
          var tax = currentPrice * TaxRate;
          setPlan(singleEvent);
          setOrderPrice(currentPrice.toFixed(2));
          setOriginalOrderPrice(orderPrice);
          setOrderTax(tax.toFixed(2));
          setOrderTotal(orderPrice.toFixed(2));
        });
      }
    });
  };

  const { fullName, email, phone, postalCode, streetAddress, country } =
    formData;
  const handleCoupon = () => {
    const userId = localStorage.getItem("user_id");
    const subadminId = localStorage.getItem("subadminId");
    // sending data for coupons with course,event or subscription
    let data = {
      couponCode: coupon,
      userId: userId,
      subadminId: subadminId,
      paymentIntentId: props.paymentIntentId
    }
    if (props.courseId) {
      data.courseId = props.courseId
      data.type = "courses"
    }
    else if (props.eventId) {
      data.eventId = props.eventId
      data.type = "events"
    }
    else if (props.subscriptionId) {
      data.subscriptionId = props.subscriptionId
      data.subscriptionFrequency = props.subscriptionFrequency
      data.type = "subscriptions"
    }
    validateAndApplyCoupon(data) // paymentIntentId, courseId, subadminId
      .then((data) => {
        setCoupon("");
        setCouponError("");
        if (data) {
          setCouponData(data.result);
          applyCoupon(data.result);
        }
      })
      .catch((err) => {
        console.log("coupon error: " + err);
        setCouponError("Invalid coupon code");
      });
  };

  const applyCoupon = (couponData) => {
    if (couponData) {
      var discountRate = couponData.discount / 100;
      var discountAmount = originalOrderPrice * discountRate;
      setDiscount(discountAmount.toFixed(2));
      var currentPrice = (originalOrderPrice - discountAmount) / 1.1;
      var tax = currentPrice * TaxRate;
      setOrderPrice(currentPrice.toFixed(2));
      setOrderTax(tax.toFixed(2));
      setOrderTotal((currentPrice + tax).toFixed(2));
    } else {
      setCouponError("Invalid coupon code");
    }
  };

  const removeCoupon = (e) => {
    e.preventDefault();
    setCoupon("");
    setCouponError("");
    setCouponData({});
    var currentPrice = originalOrderPrice / 1.1;
    setOrderPrice(currentPrice.toFixed(2));
    setDiscount(0.0);
    var tax = currentPrice * TaxRate;
    setOrderTax(tax.toFixed(2));
    setOrderTotal((currentPrice + tax).toFixed(2));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const validate = () => {
    let errors = {};

    if (!fullName) {
      errors.fullName = "*Required";
    }

    if (!email) {
      errors.email = "*Required";
    } else {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      if (!emailRegex.test(email)) {
        errors.email = "*Required";
      }
    }

    if (!country) {
      //errors.country = '*Required';
    }
    if (!streetAddress) {
      errors.streetAddress = "*Required";
    }
    if (!postalCode) {
      errors.postalCode = "*Required";
    }
    if (!phone) {
      errors.phone = "*Required";
    }

    if (!isChecked) {
      errors.isChecked = "* Please accept terms & conditions";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { fullName, email, phone, country, streetAddress, postalCode } =
      formData;
    let data = {};
    if (props.subscriptionId) {
      data = {
        fullName,
        email: email?.trim(),
        phone: phone?.trim(),
        country,
        streetAddress,
        postalCode,
        subscriptionId: props.subscriptionId,
        coupon: couponData?.couponCode,
        subscriptionType: props.subscriptionFrequency,
      };
    } else if (props.courseId) {
      data = {
        fullName,
        email: email?.trim(),
        phone: phone?.trim(),
        country,
        streetAddress,
        postalCode,
        courseId: props.courseId,
        token: localStorage.getItem("token"),
        coupon: couponData?.couponCode,
        subadminId: localStorage.getItem("subadminId"),
      };
    } else if (props.eventId) {
      data = {
        fullName,
        email: email?.trim(),
        phone: phone?.trim(),
        country,
        streetAddress,
        postalCode,
        eventId: props.eventId,
        token: localStorage.getItem("token"),
        coupon: couponData?.couponCode,
        subadminId: localStorage.getItem("subadminId"),
      };
    }

    const dataIsValid = validate();

    if (dataIsValid) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      // NEEDED for capturing before creating intent
      // // Trigger form validation and wallet collection
      // const {error: submitError} = await elements.submit();
      // if (submitError) {
      //   setMessage(submitError.message);
      //   return;
      // }

      // var result = await createMemberPayment(props.subscriptionId, props.subscriptionFrequency);
      // console.log(result, 'strip result');
      // const clientSecret = result.clientSecret;

      //var subResult = await subscribefree(props.subscriptionId);
      const subadminId = localStorage.getItem("subadminId");
      data.subadminId = subadminId;
      var subResult;
      var returnUrl;
      if (couponData?.discount == 100) {
        if (props.subscriptionId) {
          let sdata = {
            subadminId: subadminId,
            subscriptionFrequency: props.subscriptionFrequency,
            subscriptionId: {
              subsId: props.subscriptionId,
            },
          };
          subscribefree(sdata).then(async (sdata) => {
            setWarningModal(true);
            setModalMessage("You have successfully purchased subscription");
            setModalType("info");
            setWarningModalTitle("FYI");
          })
        }
        else if (props.courseId) {
          startPurchaseCourse(data).then(async (cou) => {
            let enrollInCourse = await enrollCourse(props.courseId)
            setWarningModal(true);
            setModalMessage("You have successfully purchased course");
            setModalType("info");
            setWarningModalTitle("FYI");
          })
        }
        else if (props.eventId) {
          startPurchaseEvent(data).then(async (eve) => {
            let joinevent = await joinEvent(props.eventId)
            setWarningModal(true);
            setModalMessage("You have successfully purchased event");
            setModalType("info");
            setWarningModalTitle("FYI");
          })
        }
      }
      else {
        if (props.subscriptionId) {
          returnUrl = `${window.location.origin}/complete-subscription?subscriptionId=${props.subscriptionId}`;
          subResult = await startSubscription(data);
        } else if (props.courseId) {
          returnUrl = `${window.location.origin}/complete-course?courseId=${props.courseId}`;
          subResult = await startPurchaseCourse(data);
        } else if (props.eventId) {
          returnUrl = `${window.location.origin}/complete-event?eventId=${props.eventId}`;
          subResult = await startPurchaseEvent(data);
        }

        if (subResult) {
          const { error } = await stripe.confirmPayment({
            elements,
            //clientSecret,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: returnUrl,
            },
          });

          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            //await initialize();
          } else {
            setMessage("An unexpected error occured.");
          }
        }
      }
      setIsLoading(false);
    }
  };

  const headingStyle = {
    color: brandColor,
    fontWeight: "bold",
    fonnSize: "1.5rem",
  };

  const inputBoxStyle = {
    backgroundColor: "white",
    borderColor: "#D2D2D2",
    height: "2.5em",
  };

  const priceSectionStyle = {
    fontWeight: "600",
    fonnSize: "1.5rem",
  };
  const errorMessageStyle = {
    color: "red",
    fontSize: "0.7rem",
  };
  const bullets = {
    width: "1em",
    height: "1em",
    backgroundColor: brandColor,
    borderRadius: "50%",
    marginTop: "0.5em",
    marginRight: "0.9em",
  };
  const bulletSmall = {
    width: "0.75em",
    height: "0.7em",
    backgroundColor: brandColor,
    borderRadius: "50%",
    marginTop: "0.5em",
    marginRight: "0.7em",
  };
  const lineStyle = {
    width: "55%",
    marginLeft: "5%",
    marginRight: "20%",
    marginTop: ".3em",
    marginBottom: ".3em",
    height: "1px",
    backgroundColor: "#D2D2D2",
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {showWarningModal && <ConfirmationModal
        type={modalType}
        title={warningModalTitle}
        message={modalMessage}
        freeSubsCheckModel={true}
        onClose={() => {
          setWarningModal(false);
          setMessage("");
          setModalType("");
          setWarningModalTitle("");
          if (couponData?.discount == 100) {
            navigete("/account-details");
          }
        }
        }
      />}
      <Form>
        <Row>
          <Col>
            <Form.Label style={headingStyle}>BILLING DETAILS</Form.Label>
            <Form.Group>
              <span style={errorMessageStyle}>*</span>
              <span style={{ fontSize: "0.7rem" }}> required fields</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={fullName} // Use fullName from formData
                onChange={handleChange}
                placeholder="Enter your full name"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.fullName}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={handleChange}
                value={country}
                required
                style={inputBoxStyle}
                className="country-dropdown"
              >
                {countriesList.map((country, index) => (
                  <option key={country.code + index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              {/* <span style={errorMessageStyle}>{formErrors.country}</span> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="streetAddress"
                value={streetAddress}
                onChange={handleChange}
                placeholder="Enter street address"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.streetAddress}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Postal Code / ZIP</Form.Label>
              <Form.Control
                type="number"
                name="postalCode"
                value={postalCode}
                onChange={handleChange}
                placeholder="Enter postal code"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.postalCode}</span>
            </Form.Group>
            <Form.Label style={headingStyle}>CONTACT INFORMATION</Form.Label>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.email}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={handleChange}
                placeholder="Enter your phone"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.phone}</span>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="column2">
              <Form.Label style={headingStyle}>PLAN DETAILS</Form.Label>
              <Form.Text>
                <div className="d-flex align-items-center">
                  <strong>{plan.tier}</strong>
                </div>
              </Form.Text>
              <Form.Text>
                <div className="d-flex align-items-center">
                  <span>{plan.description}</span>
                </div>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="column3">
              <Form.Label style={headingStyle}>YOUR ORDER</Form.Label>
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Plan</Form.Text>
                  </Col>

                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  {props.subscriptionId ? (
                    <Col>
                      <Form.Text>
                        Business {isMonthly ? "(Monthly)" : "(Yearly)"}
                      </Form.Text>
                    </Col>
                  ) : (
                    <Col>
                      <Form.Text>Business Onetime</Form.Text>
                    </Col>
                  )}
                  <Col>
                    <Form.Text> ${orderPrice}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Taxes</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text> ${orderTax}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Discount</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text> ${discount}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text style={priceSectionStyle}>
                      {" "}
                      ${orderTotal}
                    </Form.Text>
                    <Form.Text style={{ "font-style": "italic" }}>
                      Currency in AUD
                    </Form.Text>

                  </Col>
                  {(orderTotal-discount) < 0.5 &&
                    <div className="mt-3 d-flex align-items-center bg-light p-3 rounded w-75">
                      <i className="fas fa-info-circle me-2 text-info fs-4"></i>{" "}
                      <span className="fs-9 text-dark">
                        Our system charges 0.5 dollars when payment comes down between 0.01 to 0.5 dollars

                      </span>
                    </div>
                  }
                </Row>
              </Form.Group>
              <Form.Group controlId="column4">
                <Form.Label style={headingStyle}>COUPON</Form.Label>
                <Form.Text>
                  <strong>Have a coupon code?</strong>
                </Form.Text>
                <Row className="py-2 b-1">
                  <Col className="col-md-10">
                    <Form.Control
                      type="text"
                      placeholder="Enter your coupon code"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                      style={inputBoxStyle}
                    />
                  </Col>
                  <Col className="col-md-2">
                    <Button
                      style={{ backgroundColor: brandColor, border: "none" }}
                      type="button"
                      onClick={handleCoupon}
                      disabled={coupon === ""}
                    >
                      Apply
                    </Button>
                  </Col>
                  <Col className="col-md-12">
                    <span style={{ color: "grey", fontSize: "0.7rem" }}>
                      Only one coupon is allowed
                    </span>
                  </Col>
                  <Col className="col-md-12">
                    <span style={errorMessageStyle}>
                      {couponError ? couponError : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  {couponData?.couponCode && (
                    <Col>
                      <span style={{ fontSize: "0.8rem" }}>
                        {couponData?.couponCode}
                      </span>
                      <span style={{ fontSize: "0.8rem", marginLeft: '1em' }}>
                        {couponData?.displayText.slice(0, 40)}
                      </span>
                      <Button variant="link" onClick={removeCoupon}>
                        <FontAwesomeIcon icon={faXmark} color="red" />
                      </Button>
                    </Col>
                  )}
                </Row>
              </Form.Group>
            </Form.Group>
            {/* <Form.Group>
                                    <Form.Label type="text" placeholder="Input 2" style={headingStyle}>HAVE A COUPEN</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control type='text' style={{ height: "2.6em", witdth: "10em", backgroundColor: "white", borderColor: "#D2D2D2" }} />
                                        </Col>
                                        <Col>
                                            <Button style={{ backgroundColor: "#12AEB4", border: "none" }}>Apply</Button>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
            <Form.Group>
              {/* <Form.Label style={headingStyle}>PAYMENT METHOD</Form.Label>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            label="Creadit Card (Payrix)"
                                            id="checkbox"
                                            style={inputBoxStyle}>
                                        </Form.Check>
                                        <Form.Control type='number' placeholder='Card Number' style={inputBoxStyle} />
                                        <Form.Control type='number' placeholder='Name on Card' style={inputBoxStyle} />
                                    </Form.Group> */}
              {/* <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            label="BPAY"
                                            id="checkbox"
                                            style={inputBoxStyle}>
                                        </Form.Check>
                                        <Form.Control type='number' placeholder='Card Number' style={inputBoxStyle} />
                                    </Form.Group> */}

              <Form.Group>
                {couponData?.discount != 100 && <PaymentElement id="payment-element" />}
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="checkboxId"
                  label="I have read and agree to the website terms and conditions"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  required
                />
                <span style={errorMessageStyle}>{formErrors.isChecked}</span>
              </Form.Group>
              <Form.Group>
                <Form.Text>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <a target="blank" href="/privacy-policy">
                    privacy policy
                  </a>
                  .
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Button
                  style={{ backgroundColor: brandColor, border: "none" }}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Place Order
                </Button>
              </Form.Group>
              <Form.Group className="secure-checkout-section">
                <img src={secureCheckout} />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
}
