import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

if(!BACKEND_API_URL) {
  throw new Error('REACT_APP_BACKEND_API_URL environment variable not defined')
}

const BACKEND_SERVER_URL = BACKEND_API_URL.replace(/\/api/, "");

const SOCKETIO_EVENTS = {
    // Please sync these event names on all the repos whenever you make changes
    // api: socketio.js
    // member: src\helper\hooks\useSockets.js
    // ========================
    CONNECTION: "connection",
    NEW_USER: "newUser",
    GIVE_NOTIFICATION: "giveNotification",
    TAKE_NOTIFICATION: "takeNotification",
    TAKE_NOTIFICATIONS: "takeNotifications",
    DISCONNECT: "disconnect",
}

const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Connect to the Socket.IO server
    //console.log(BACKEND_SERVER_URL,"BACKEND_SERVER_URL")
    const newSocket = io(BACKEND_SERVER_URL);

    // Set the socket in the state
    setSocket(newSocket);

    // Clean up the socket connection on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  return {socket, events: SOCKETIO_EVENTS};
};

export default useSocket;
