import React, { useEffect, useState, useCallback } from 'react';
import { courseSnapshot } from '../../helper/service';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import leftArrow from '../../images/arrow-left.png';
import hexToRgba from "hex-to-rgba";
import { useNavigate, useParams } from 'react-router-dom';

function LessonsSidebar({ moduleId, setLastLessonClickedId, lessonId, setCourseTotalLesson, setCourseCompletedLesson, checkForGetUpdatedData }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [show, setShow] = useState(true);
  const brandColor = localStorage.getItem('subadminBrandColor');
  const opacityColor = hexToRgba(brandColor, "0.5");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [courseId, setCourseId] = useState();
  const [openModules, setOpenModules] = useState({});
  const [courseData, setCourseData] = useState([]);
  const [courseTitle, setCourseTitle] = useState();
  const [courseProgress, setCourseProgress] = useState({
    totalLessons: 0,
    completedLessons: 0,
    progressPercent: 0
  });

  const fetchCourseData = useCallback(async () => {
    let courseid = localStorage.getItem("couseIdForLesson")
    setCourseId(courseid)
    const data = await courseSnapshot(courseid);
    if (data && data.result) {
      let lastData = data.result[data.result?.length - 1]
      setLastLessonClickedId(lastData[lastData?.length - 1]._id)
      setCourseData(data.result);
      setCourseTitle(data.result[0][0].courseOrModTitle)
      let initialOpenModules = {};
      const tempCourseOrModuleId = moduleId ? moduleId : courseid;

      data.result.forEach((item, index) => {
        if ((item[0].moduleId && item[0].moduleId === tempCourseOrModuleId) || (!item[0].moduleId && item[0].courseId === tempCourseOrModuleId)) {
          initialOpenModules[index] = true;
        }
      });

      setOpenModules(initialOpenModules);
      let totalLessons = 0;
      let completedLessons = 0;
      data.result.forEach(item => {
        totalLessons += item.length;
        completedLessons += item.filter(lesson => lesson?.lessonStatus === "completed").length;
      });
      setCourseCompletedLesson(completedLessons)
      setCourseTotalLesson(totalLessons)

      const progressPercent = (completedLessons / totalLessons) * 100;
      setCourseProgress({ totalLessons, completedLessons, progressPercent });
    }
  }, [courseId, moduleId, setCourseCompletedLesson, setCourseTotalLesson, setLastLessonClickedId]);

  useEffect(() => {
    let checkOpenedBefore = localStorage.getItem("showSidebar")
    if (!checkOpenedBefore) {
      setShow(true)
      localStorage.setItem("showSidebar", true)
    }
    fetchCourseData();
  }, [id, checkForGetUpdatedData, fetchCourseData]);

  const moveToNextLesson = (currentLesson, prevLessonStatus, index) => {
    console.log(currentLesson?._id, currentLesson?.lessonCompletionRestriction, prevLessonStatus, index, "lesson details")
    if (index === 0) {
      navigate(`/lesson/${currentLesson?._id}`);
    }
    else if ((prevLessonStatus === 'completed' || prevLessonStatus === "" || prevLessonStatus === ' ' || prevLessonStatus === "started" || prevLessonStatus === undefined || prevLessonStatus === null) && !currentLesson?.lessonCompletionRestriction) {
      navigate(`/lesson/${currentLesson?._id}`);
    }
    else if (prevLessonStatus === 'completed' && currentLesson?.lessonCompletionRestriction) {
      navigate(`/lesson/${currentLesson?._id}`);
    }
    else if (prevLessonStatus !== 'completed' && currentLesson?.lessonCompletionRestriction) {
      alert("Complete the previous lesson to move next");
    }
  };

  const toggleModule = (event, index) => {
    event.preventDefault();
    setOpenModules(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <>
      <img src={leftArrow} style={{ zIndex: 999, width: '4em', height: 'auto', position: 'absolute', top: '5em', right: '0.3em', cursor: 'pointer' }} onClick={handleShow} />

      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body scroll={false}>
          <div className='progress-box'>
            <div className='progress-container'>
              <label className='course-title' style={{ color: `${brandColor}`, textTransform: 'capitalize' }}>{courseTitle}</label>
              <div className='progress-bar-background'>
                <div
                  className='progress-bar-fill'
                  style={{ width: `${courseProgress.progressPercent}%` }}
                />
              </div>
              <div className='progress-text'>
                {courseProgress.completedLessons} of {courseProgress.totalLessons} Lessons Completed.
              </div>
            </div>
            <div className='trophy'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trophy-fill" viewBox="0 0 16 16">
                <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935" />
              </svg>
            </div>
          </div>
          <div className='section pb-5'>
            <div className='section-item'>
              {courseData.length > 0 && courseData.map((item, index) => {
                const completedCount = item.filter(lesson => lesson?.lessonStatus === "completed").length;
                let prevLessonStatus = '';
                return (
                  <div key={index} className='section-item'>
                    <button className='section-btn' onClick={(event) => toggleModule(event, index)}>
                      <span style={{ marginLeft: '2.2em' }}> {item[0]?.courseOrModTitle}</span>
                      <i className={`right fa fa-angle-right dropdown ${openModules[index] ? 'rotated' : ''}`} style={{ bottom: '-0.4em' }} />
                      <div className='section-lesson-completeion-checkbox' type='button' style={completedCount === item.length ? { background: `${brandColor}` } : {}}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          className="bi bi-check-square"
                          viewBox="0 0 16 16"
                          style={completedCount === item.length ? { visibility: "visible" } : {}}
                        >

                          <circle cx="8" cy="8" r="5" />
                        </svg>

                      </div>
                      <div className='counter'>
                        {completedCount}/{item.length}
                      </div>
                    </button>
                    {openModules[index] && (
                      <div>
                        {item.sort((item1, item2) => item1.lessonOrder - item2.lessonOrder).map((lesson, i) => {
                          const currentLessonStatus = lesson.lessonStatus;
                          const previousLessonStatus = prevLessonStatus;
                          prevLessonStatus = currentLessonStatus;

                          return (
                            <div key={lesson._id} className={`sub-items ${lessonId === lesson._id ? 'sub-items-selected' : ''}`} onClick={() => moveToNextLesson(lesson, previousLessonStatus, i)}>
                              <div className='lesson-status-checkbox' type='button' style={lesson.lessonStatus === 'completed' ? { background: '#2d2f31' } : {}}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="white"
                                  className="bi bi-check-square"
                                  viewBox="0 0 16 16"
                                  style={lesson.lessonStatus === 'completed' ? { visibility: "visible" } : {}}
                                >

                                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                </svg>

                              </div>
                              <div className='items'>{lesson.title}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default LessonsSidebar;
