import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import adminImg from "../../images/dashboard/admin-img.png";
import {
  userInfo,
  usersubscriptionData,
  subscribefree,
} from "../../helper/service";
import { commonUtil } from "../../helper/CommonUtils";
import { useAuth } from "../../contexts/auth";

const brandColor = localStorage.getItem("subadminBrandColor");

export default function AccountDetails() {
  const [isLoader, setIsLoader] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(adminImg);
  const [yoursubscriptionData, setYourSubscriptionData] = useState("");
  const [subscriptionData, setSubscriptionData] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    if (user) {
      updateUserData(user);
    } else {
      const userId = localStorage.getItem("user_id");
      setIsLoader(true);
      userInfo(userId).then((data) => {
        updateUserData(data.result.validUser);
        setIsLoader(false);
      });
    }
  };

  const updateUserData = (user) => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPhone(user.mobile);
    setPhoto(user.image);
    let subs = localStorage.getItem("subscriptionId")
    let partnerId = localStorage.getItem("subadminId")
    const index = user.subadminsId.findIndex(subadmin => subadmin?.subadminId?._id === partnerId);
    let subscription = user.subscriptionId[index];
    if (subs !=='undefined' && subscription) {
      yoursubscriptionlist(user);
    }

    usersubscriptionData().then((subs) => {
      const newData = subs.result.filter(item => !item.hideMembershipLevel);
      subscriptionlist(newData, user);
    });
  }

  const selectFreePlan = async (planId, completeCheck) => {
    let previousSubs = localStorage.getItem("subscriptionId")
    let data = {
      subscriptionId: {
        subsId: planId,
        prevSubsId: previousSubs
      },
      subadminId:localStorage.getItem("subadminId")
    };
    subscribefree(data).then((data) => {
      if (completeCheck == "complete") {
        alert("You are going to buy free membership.");
      }
      else {
        alert("You have selected free membership.");
      }
      commonUtil.reload();
    });
  };

  const selectPlan = async (planId, tier) => {
    let url = `/checkout/${planId}/${tier}`;
    commonUtil.redirect(url);
  };

  const subscriptionlist = (subscription, user) => {
    let partnerId = localStorage.getItem("subadminId")
    const index = user.subadminsId.findIndex(subadmin => subadmin?.subadminId?._id === partnerId);
    let currentSubscription = [user.subscriptionId[index]];
    // var currentSubscription = user.subscriptionId;
    if (subscription) {
      console.log(subscription);
      var datas = subscription.map(function (subs) {
        let subsDescription;
        if (subs.description.length > 25) {
          subsDescription = `${subs?.description?.substring(0, 25)}....`;
        }
        let show = false;
        if (
          currentSubscription.length > 0 &&currentSubscription[0]?.status=="Active"&&
          currentSubscription[0]?.subsId?._id === subs._id
        ) {
          show = false;
        } else if (subs.tier === "Eff-Ex Mastermind") {
          if (
            user.email === "jodie@jodiehollis.com.au" ||
            user.email === "ann@postprepress.com.au" ||
            user.email === "lisa.hawkins@lifebalanceessentials.com" ||
            user.email === "design@alltogethercreative.com" ||
            user.email === "sandra@moneywellness.com.au" ||
            user.email === "carol@msproperty.com.au" ||
            user.email === "adorzaces@gmail.com" ||
            user.email === "nikkiandjoe22@gmail.com" ||
            user.email === "inbox.naeemashraf@gmail.com" ||
            user.email === "ivyq82165@gmail.com" ||
            user.email === "ceciliajan091@gmail.com" ||
            user.email === "macappscoptyltd@gmail.com"
          ) {
            show = true;
          } else {
            show = false;
          }
        } else {
          show = true;
        }

        if (show) {
          if (!subs?.pauseMembershipLevel) {
            const monthlyFeeForYearlyPlan = (subs.amountYearly / 12).toFixed(2);
            return (
              <div className="card explore-plans">
                <div
                  className="card-body d-flex flex-column"
                  style={{ height: "100vh" }}
                >
                  <div className="col d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center flex-column">
                      <img src={subs?.thumbnailImg} height="120" width="120" />
                      <p
                        className="plan-name mt-2"
                        style={{
                          color: brandColor,
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {subs.tier}
                      </p>
                      <p
                        className="plan-fee text-right"
                        style={{
                          color: brandColor,
                          fontSize: "0.9rem",
                          marginBottom: "0",
                        }}
                      >
                        {subs.amountMonthly !== undefined &&
                          subs.amountMonthly !== null &&
                          subs.amountMonthly !== 0 ? (
                          <span>
                            ${monthlyFeeForYearlyPlan}/Monthly
                            <br />
                          </span>
                        ) : (
                          <span>
                            $0.00/Monthly
                            <br />
                          </span>
                        )}
                      </p>
                      <p
                        className="plan-fee text-right"
                        style={{
                          color: brandColor,
                          fontSize: "0.8rem",
                          marginBottom: "0.2em",
                        }}
                      >
                        {subs.amountYearly !== undefined &&
                          subs.amountYearly !== null &&
                          subs.amountYearly !== 0 ? (
                          <span>
                            (Charged yearly ${subs.amountYearly})
                            <br />
                          </span>
                        ) : (
                          <span>
                            (Charged yearly $0.00)
                            <br />
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  {subs.description.length > 25 ? (
                    <p class="hover-text text-justify mt-3">
                      {subsDescription}
                      <span class="tooltip-text">{subs.description}</span>
                    </p>
                  ) : (
                    <p className="text-justify mt-3">{subs.description}</p>
                  )}
                  <div className="d-flex justify-content-center d-flex justify-content-center mt-auto">
                    {subs.amountMonthly || subs.amountYearly ? (
                      <>
                        <div className="d-flex flex-column">
                          <button
                            className="btn btn-custom btn-enlarge"
                            style={{
                              backgroundColor: `${brandColor !== null ? brandColor : ""
                                }`,
                              height: "4.4em",
                            }}
                            onClick={() => {
                              selectPlan(subs._id, "yearly");
                            }}
                          >
                            <label>
                              <strong>
                                Purchase at $
                                {(subs.amountYearly / 12).toFixed(2)} / month
                              </strong>
                            </label>
                            <lable>Charged ${subs.amountYearly} / year</lable>
                          </button>
                          <button
                            className="btn btn-custom"
                            style={{
                              backgroundColor: `${brandColor !== null ? brandColor : ""
                                }`,
                            }}
                            onClick={() => {
                              selectPlan(subs._id, "monthly");
                            }}
                          >
                            Purchase at ${subs.amountMonthly} / month
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex flex-column">
                        <button
                          className="btn btn-custom"
                          style={{
                            backgroundColor: `${brandColor !== null ? brandColor : ""
                              }`,
                          }}
                          onClick={() => {
                            selectFreePlan(subs._id);
                          }}
                        >
                          Susbscribe for Free
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        }
      }, this);
    } else {
      var datas = "";
    }
    setSubscriptionData(datas);
  };

  const yoursubscriptionlist = (user) => {
    let partnerId = localStorage.getItem("subadminId")
    const index = user.subadminsId.findIndex(subadmin => subadmin?.subadminId?._id === partnerId);
    let subscription = [user.subscriptionId[index]];
    if (subscription) {
      var datas = subscription.map(function (subs) {
        var startDate = new Date(subs?.createdAt);
        var startDate1 = startDate?.toDateString();

        var endDate = new Date(subs?.endDate);
        var endDate1 =
          endDate instanceof Date && !isNaN(endDate.valueOf())
            ? endDate.toDateString()
            : "";

        if (subs?.status != "In-Active") {
          return (
            <div className="card plans">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={subs?.subsId?.thumbnailImg}
                        height="120"
                        width="120"
                      />
                      <p className="plan-name mt-2">{`${subs?.subsId?.tier} (${user.subsPlan})`}</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="plan-fee text-right">
                      {subs?.subsId?.amountMonthly ? (
                        <>
                          Monthly / ${subs?.subsId?.amountMonthly} <br />
                        </>
                      ) : (
                        ""
                      )}
                      {subs?.subsId?.amountYearly ? (
                        <>Yearly / ${subs?.subsId?.amountYearly}</>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="subsc-date">Subscription Date</p>
                  </div>
                  <div className="col-6">
                    <p className="subsc-date text-right">{startDate1}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="expiry-date">Expiry Date</p>
                  </div>
                  <div className="col-6">
                    <p className="expiry-date text-right">{endDate1}</p>
                  </div>
                </div>
                {subs?.status !== "Invited" ? (
                  <></>
                ) : (
                  ((subs?.subsId?.amountMonthly || subs?.subsId?.amountYearly) ?
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-custom"
                        style={{
                          backgroundColor: `${brandColor !== null ? brandColor : ""
                            }`,
                        }}
                        onClick={() => selectPlan(subs?.subsId?._id, user.subsPlan)}
                      >
                        Complete subscription
                      </button>
                    </div> :
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-custom"
                        style={{
                          backgroundColor: `${brandColor !== null ? brandColor : ""
                            }`,
                        }}
                        onClick={() => {
                          selectFreePlan(subs?.subsId?._id, "complete");
                        }}
                      >
                        Complete subscription
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          );
        }
      }, this);
    } else {
      var datas = "";
    }
    setYourSubscriptionData(datas);
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="content-wrapper">
        <section className="profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                <div className="tab-content" id="profile-tabContent">
                  <div
                    className="tab-pane fade show active show"
                    id="account-details"
                    role="tabpanel"
                    aria-labelledby="account-details-tab"
                  >
                    <h2 className="title">Account Details</h2>
                    <img src={photo} className="user-img" />

                    <div className="row details">
                      <span className="col-sm-5">First Name</span>
                      <div className="col-sm-7">
                        <label>{first_name}</label>
                      </div>
                    </div>
                    <div className="row details">
                      <span className="col-sm-5">Last Name</span>
                      <div className="col-sm-7">
                        <label>{last_name}</label>
                      </div>
                    </div>
                    <div className="row details">
                      <span className="col-sm-5">Contact No.</span>
                      <div className="col-sm-7">
                        <label>{phone}</label>
                      </div>
                    </div>
                    <div className="row details">
                      <span className="col-sm-5">Email Address</span>
                      <div className="col-sm-7">
                        <label>{email}</label>
                      </div>
                    </div>
                    <h2 className="title">Your Plans</h2>
                    <div className="d-flex flex-wrap">
                      {yoursubscriptionData}
                    </div>
                    <h2 className="title">Explore Plans</h2>
                    <div className="d-flex flex-wrap">{subscriptionData}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
