import React, { useEffect } from 'react';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import slideleft1 from '../../images/slide-left1.svg';
import slideright1 from '../../images/slide-right1.svg';
import magnifiyingGlass from '../../images/admin/magnifiying-glass.svg';
import searchDark from '../../images/enduser/search-dark.svg';
const carouselOptions = {
  stagePadding: 80,
  margin: 30,
  dots: false,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  navText: [`<img src=${slideleft1} />`, `<img src=${slideright1} />`],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
      stagePadding: 0,
    },
    700: {
      items: 2,
    },
    1300: {
      items: 3,
    }
  },
};
export default class FolderComponent extends React.Component {

  render() {

    // $(document).ready(function () {
    //   $('.coursesTabContent').attr('id', 'coursesTabContent');
    // })



    return (
      <section class="courses-folders">
        <div class="container-fluid">
          {/* <div class="row">
          <div class="col-xl-8">
          <div class="d-flex justify-content-left align-items-center flex-wrap">
          <label>Sort by :</label>
          <select class="form-control sort">
          <option> Name </option>
          </select> 
          <img src="images/admin/Group 68030.svg" class="mx-3" />  
          <img src="images/admin/calendar (8).svg" class="mx-3" />  
          <input type="text" name="" class="form-control mx-3 date" placeholder="21 - Jan - 2020" /> 
          </div>     
          </div>
          <div class="col-xl-4">
          <div class="d-flex justify-content-end align-items-center">
          <button type="button" class="btn btn-custom">View All</button>
          </div>
          </div>
          </div> */}

          <div class="row">
            <div class="col-md-12">
              <ul class="nav nav-tabs" id="coursesTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="recentlyAdded-tab" data-toggle="tab" onClick={this.props.getRecentCourses} role="tab" aria-controls="recentlyAdded" aria-selected="true">Recently added</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="scheduled-tab" data-toggle="tab" onClick={this.props.getScheduledCourses} role="tab" aria-controls="scheduled" aria-selected="false">Scheduled Courses</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="top-tab" data-toggle="tab" onClick={this.props.getTopCourses} role="tab" aria-controls="top" aria-selected="false">Top courses</a>
                </li>
                {/*<li class="nav-item">*/}
                {/*<a class="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab" aria-controls="viewall" aria-selected="false">View All <i class="fas fa-angle-right"></i></a>*/}
                {/*</li>*/}
                <li className="ml-auto">
                  <div className="search-course">
                    <input type="text" className="form-control" placeholder="Search Course" name="search" onChange={this.props.searchData} />
                    <span className="search-icon"><img src={searchDark} /></span>
                  </div>
                </li>
              </ul>
              <OwlCarousel className='discover-slider courses-slider owl-carousel owl-theme' {...carouselOptions}  >
                {this.props.folders}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    )
  }
}