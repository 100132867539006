import React, { useState, useEffect } from "react";
import membership from "../images/membership-bg.svg";

export default function Welcome() {
  const [isRedirect, setIsRedirect] = useState(false);

  const submit = () => {
    setIsRedirect(true);
  };

  let membershipName = localStorage.getItem("membershipName");
  let subadminImage = localStorage.getItem("subadminImage");
  let subadminBrandColor = localStorage.getItem("subadminBrandColor");
  let companyName = localStorage.getItem("companyName");
  let welcomeNote = localStorage.getItem("welcomeNote");

  document.body.style.backgroundImage = "url(" + membership + ")";

  if (isRedirect) {
    window.location = "/dashboard";
  }

  return (
    <section id="user-welcome" className="py-3" style={{ height: "auto" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body text-center">
                <div
                  className="company-info d-flex justify-content-center align-items-center"
                  style={{ backgroundColor: subadminBrandColor }}
                >
                  <img
                    src={subadminImage}
                    className="mr-2 img-fluid"
                    alt=""
                    width="60"
                    height="60"
                  />
                  <p className="company-name">
                    {companyName && companyName !== "undefined" && (
                      <>
                        <span>{companyName}</span> <br />
                      </>
                    )}
                    <small>{membershipName}</small>
                  </p>
                </div>
                <h2 className="user-welcome-title">Welcome to</h2>
                {companyName && companyName !== "undefined" && (<p className="user-welcome-text">{companyName} </p>)}
                {/* <p className="user-welcome-text scroll "
                    >{welcomeNote} </p> */}

                <p
                  className="user-welcome-note"
                  dangerouslySetInnerHTML={{ __html: welcomeNote }}
                ></p>

                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-info"
                    style={{ backgroundColor: subadminBrandColor }}
                    onClick={submit}
                  >
                    ENTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
